import { PhoneNumberUtil } from 'google-libphonenumber';

export const getIsoCodeByPhone = (
    phone: string,
): { countryLetterCode: string; countryDialCode: number } | null => {
    try {
        const phoneUtil = PhoneNumberUtil.getInstance();

        const parsedNumber = phoneUtil.parse(`+${phone}`);
        const countryDialCode = parsedNumber.getCountryCode() as number;

        const countryLetterCode =
            phoneUtil.getRegionCodeForCountryCode(countryDialCode);

        return { countryLetterCode, countryDialCode };
    } catch (error) {
        console.error('Error parsing phone number:', error);

        return null;
    }
};
