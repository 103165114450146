import Components from 'formiojs/components/Components';

const SelectComponent = (Components as any).components.select;

class Select extends (SelectComponent as any) {
    attach(element: any) {
        super.attach(element);

        if (this.refs.autocompleteInput) {
            const input = this.element.querySelectorAll(
                '.formio-select-autocomplete-input',
            );
            element?.removeChild(input?.[0]);
        }
    }
}

export default Select;
