/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { useEffect } from 'react';

export const usePlaid = (formReference: React.MutableRefObject<any>) => {
    useEffect(() => {
        window.setTimeout(() => {
            formReference.current?.formio?.on('change', (changed: any) => {
                const formInstancePublicToken = changed?.data?.publicToken;
                if (
                    _.has(formReference.current?.formio?.data, 'publicToken') &&
                    !_.isNil(formInstancePublicToken) &&
                    formInstancePublicToken &&
                    formReference?.current?.formio
                ) {
                    formReference.current.formio.data.publicToken =
                        formInstancePublicToken;
                    formReference.current?.formio?.submit();
                }
            });
        }, 0);
    }, []);
};
