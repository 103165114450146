/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import _ from 'lodash';
import { action, computed, observable } from 'mobx';
import { USER_CHANGED_LANG_KEY } from 'src/components/LanguageSwitcher/LanguageSwitcher';
import { Router } from 'src/core/router/Router';
import { UtmParam } from 'src/generated-api-client';
import { borrowerApi } from 'src/services/apiServices';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { LocalStoreHelper } from 'src/utils/LocalStoreHelper';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { RequestHelper } from 'src/utils/RequestHelper';
import { UrlHelper } from 'src/utils/UrlHelper';

export type UserProfile = {
    username: string;
};
export class UserStoreClass {
    @observable private _userProfile?: Keycloak.KeycloakProfile;

    @computed get userProfile() {
        return this._userProfile;
    }

    @action async loadUserInfo() {
        const isUserSetLang = LocalStoreHelper.getItem(USER_CHANGED_LANG_KEY);
        const resp = await SettingsStore.keycloak?.loadUserProfile();
        this._userProfile = resp;
        // @ts-ignore
        const keycloakLocale = resp?.attributes?.locale?.[0];

        if (keycloakLocale && !isUserSetLang) {
            SettingsStore.setLocale(keycloakLocale);
        }
    }

    sendUTMAction = new AsyncOperationWithStatus((utmData: UtmParam[]) =>
        RequestHelper.unwrapFromAxiosPromise(
            borrowerApi.saveUtmParams(utmData),
        ),
    );

    @action async sendUTM() {
        const urlSearchParams = Router.getQuerySearchParams();

        const utmData = UrlHelper.getUTMFromUrl(urlSearchParams || '');

        if (!_.isEmpty(utmData)) {
            await this.sendUTMAction.call(utmData);

            if (!this.sendUTMAction.hasError) {
                UrlHelper.removeUTMFromUrl();
            }
        }
    }

    @computed get roles() {
        const realmAccessRoles =
            SettingsStore.keycloak?.tokenParsed?.realm_access?.roles || [];

        const resourceAccessRoles =
            (
                SettingsStore.keycloak!.tokenParsed?.resource_access?.[
                    SettingsStore.settings?.appName || ''
                ] as any
            )?.roles || [];

        return [...resourceAccessRoles, ...realmAccessRoles] as string[];
    }

    hasRole(role: string) {
        return (
            this.roles
                ?.map((x) => x.toLowerCase())
                .includes(role.toLowerCase()) || false
        );
    }

    hasAnyRole(...roles: string[]) {
        return (
            _.intersection(
                this.roles?.map((role) => role.toLowerCase()),
                roles.map((role) => role.toLowerCase()),
            ).length > 0
        );
    }
}
export const UserStore = new UserStoreClass();
