export const form = `
{% if (ctx.options.vpat) { %}
  <span tabindex="-1" class="sr-only" id="invisible-{{ctx.instance.id}}-{{ctx.component.key}}"></span>
{% } %}

  {% if (ctx.options.vpat) { %}
    <div>{{(!ctx.component.filePattern || ctx.component.filePattern === '*') ? 'Any file types are allowed' : ctx.t('Allowed file types: ') + ctx.component.filePattern}}</div>
  {% } %}
  <div class="files">
    {% ctx.files.forEach(function(file) { %}
      <div class="file" data-url="{{file.url.replace(file.name, file?.data?.persistedFileName || file?.name)}}" data-type="{{file.type}}" data-name="{{file.name}}" data-original-name="{{file.originalName}}">
        
        <div class="file__thumbnail" style="{{ (file.type && file.type.indexOf('image')) > -1 ? 'background-image: url(' + encodeURI(file.url.replace(file.name, file?.data?.persistedFileName || file?.name)) + ');' : '' }}">
        
        </div>

        <div class="file__footer">
          <div class="file-type file-type__{{file.type && file.type.split('/')[0]}}"></div>
          <div class="file-name">
            {{file.originalName || file.name}}
          </div>
        </div>

        <div class="file__overlay">
          {% if (!ctx.disabled) { %}
            <div class="file__overlay-header">
              <div class="file-remove" ref="removeLink"><i tabindex="0" class="{{ctx.iconClass('remove')}}" ></i></div>
            </div>
          {% } %}
          <div class="file__overlay-body">
            {% if (ctx.component.uploadOnly) { %}
              {{file.originalName || file.name}}
            {% } else { %}
              <a class="btn btn-secondary" href="{{file.url || '#'}}" target="_blank" ref="fileLink" download="{{file.originalName || file.name}}">
                <i class="icon fa fa-download" aria-hidden="true"></i>
              </a>
            {% } %}
          </div>
        </div>
      </div>
    {% }) %}
  </div>
{% if (!ctx.disabled && (ctx.component.multiple || !ctx.files.length)) { %}
  {% if (ctx.self.useWebViewCamera) { %}
    <div class="fileSelector">
      <button class="btn btn-primary" ref="galleryButton"><i class="fa fa-book"></i> {{ctx.t('Gallery')}}</button>
      <button class="btn btn-primary" ref="cameraButton"><i class="fa fa-camera"></i> {{ctx.t('Camera')}}</button>
    </div>
  {% } else if (!ctx.self.cameraMode) { %}
    <div class="fileSelector" ref="fileDrop" {{ctx.fileDropHidden ?'hidden' : ''}}>
      <i class="{{ctx.iconClass('cloud-upload')}}"></i> {{ctx.t('Drop files to attach,')}}
        {% if (ctx.self.imageUpload && ctx.component.webcam) { %}
          <a href="#" ref="toggleCameraMode"><i class="fa fa-camera"></i> {{ctx.t('use camera')}}</a>
        {% } %}
        {{ctx.t('or')}}
        <a href="#" ref="fileBrowse" class="browse">
          {{ctx.t('browse')}}
          <span class="sr-only">
            {{ctx.t('Browse to attach file for ' + ctx.component.label + '. ' + 
            (ctx.component.description ? ctx.component.description + '. ' : '') + 
            ((!ctx.component.filePattern || ctx.component.filePattern === '*') ? 'Any file types are allowed' : ctx.t('Allowed file types: ') + ctx.component.filePattern))}}
          </span>
        </a>
      <div ref="fileProcessingLoader" class="loader-wrapper">
        <div class="loader text-center"></div>
      </div>
    </div>
  {% } else { %}
    <div class="video-container">
      <video class="video" autoplay="true" ref="videoPlayer" tabindex="-1"></video>
    </div>
    <button class="btn btn-primary" ref="takePictureButton"><i class="fa fa-camera"></i> {{ctx.t('Take Picture')}}</button>
    <button class="btn btn-primary" ref="toggleCameraMode">{{ctx.t('Switch to file upload')}}</button>
  {% } %}
{% } %}
{% ctx.statuses.forEach(function(status) { %}
  <div class="file {{ctx.statuses.status === 'error' ? ' has-error' : ''}}">
    <div class="row">
      <div class="fileName col-form-label col-sm-10">{{status.originalName}}
        <i class="{{ctx.iconClass('remove')}}" ref="fileStatusRemove">
          <span class="sr-only">{{ctx.t('Remove button. Press to remove ' + status.originalName || status.name + '.')}}</span>
          <span class="sr-only">{{status.message ? status.message.replace(';', '.') : ''}}</span>
        </i>
      </div>
      <div class="fileSize col-form-label col-sm-2 text-right">{{ctx.fileSize(status.size)}}</div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        {% if (status.status === 'progress') { %}
          <div class="progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="{{status.progress}}" aria-valuemin="0" aria-valuemax="100" style="width: {{status.progress}}%">
              <span class="sr-only">{{status.progress}}% {{ctx.t('Complete')}}</span>
            </div>
          </div>
        {% } else if (status.status === 'error') { %}
          <div class="alert alert-danger bg-{{status.status}}">{{ctx.t(status.message)}}</div>
        {% } else { %}
          <div class="bg-{{status.status}}">{{ctx.t(status.message)}}</div>
        {% } %}
      </div>
    </div>
  </div>
{% }) %}
{% if (!ctx.component.storage || ctx.support.hasWarning) { %}
  <div class="alert alert-warning">
    {% if (!ctx.component.storage) { %}
      <p>{{ctx.t('No storage has been set for this field. File uploads are disabled until storage is set up.')}}</p>
    {% } %}
    {% if (!ctx.support.filereader) { %}
      <p>{{ctx.t('File API & FileReader API not supported.')}}</p>
    {% } %}
    {% if (!ctx.support.formdata) { %}
      <p>{{ctx.t("XHR2's FormData is not supported.")}}</p>
    {% } %}
    {% if (!ctx.support.progress) { %}
      <p>{{ctx.t("XHR2's upload progress isn't supported.")}}</p>
    {% } %}
  </div>
{% } %}`;
