/* eslint-disable camelcase */
import { notification } from 'antd';
import { useEffect } from 'react';

type IllionEventTypes =
    | 'submission_complete'
    | 'bank_selected'
    | 'login_clicked'
    | 'accounts_selected'
    | 'submit_all';

interface IllionMessage {
    source: string;
    event: IllionEventTypes;
    status: 'success' | 'error';
    error_code?: number;
    error_message?: string;
}

export const useIllion = (formReference: React.MutableRefObject<any>) => {
    const onMessageHandler = (event: MessageEvent) => {
        try {
            if (event?.data === 'unchanged') return;

            const message = JSON.parse(event?.data || {}) as IllionMessage;

            if (
                message.event === 'submit_all' &&
                message.status === 'success'
            ) {
                // Process successful submission
                formReference.current?.formio?.submit();
            } else if (
                message.event === 'submission_complete' &&
                message.status === 'error'
            ) {
                notification.error({
                    message: message.error_message,
                });
                console.error('Submission error:', message.error_message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        window.addEventListener('message', onMessageHandler);

        return () => {
            window.removeEventListener('message', onMessageHandler);
        };
    }, []);
};
