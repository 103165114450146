import axios, { AxiosRequestConfig } from 'axios';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';

axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
    return {
        ...config,
        headers: {
            ...config.headers,
            ...(await getAuthHeader()),
        },
    };
});
const getAuthHeader = async () => {
    try {
        await SettingsStore.updateTokenIfNeed();
    } catch {
        AuthStore.authenticate();
    }

    if (AuthStore.token) {
        return {
            Authorization: `bearer ${AuthStore.token}`,
        };
    }

    return {};
};
