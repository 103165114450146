import Inputmask from 'inputmask';
import { getIsoCodeByPhone } from 'src/formio/utils/getIsoCodeByPhone';
import { getMaskByIsoPhone } from 'src/formio/utils/getMaskByIsoPhone';

export const getMaskedPhoneValue = (value: any) => {
    const phoneData = getIsoCodeByPhone(value);

    const mask = getMaskByIsoPhone(
        phoneData?.countryLetterCode as string,
        value,
    );

    const phoneMask = new Inputmask(mask);

    return (phoneMask as any).format(
        value?.replace('+', '')?.replace(phoneData?.countryDialCode, ''),
    );
};

const getFormattedValue = (ctx: any, value: any) => {
    return ctx.instance
        .getValueAsString(
            ctx.instance.formatValue(ctx.instance.parseValue(value)),
        )
        .replace(/"/g, '&quot;');
};

export const getDisabledValue = (ctx: any) => {
    const hasCalculatedValue = ctx?.component?.calculateValue;

    if (hasCalculatedValue) {
        return getFormattedValue(
            ctx,
            ctx.instance.doValueCalculation(
                ctx.instance.dataValue,
                ctx.data,
                ctx.row,
                {},
            ),
        );
    }

    if (ctx.component.type === 'phoneNumber') {
        return getMaskedPhoneValue(ctx.instance.getValue());
    }

    return getFormattedValue(ctx, ctx.instance.getValue());
};
