import { action } from 'mobx';
import { TaskRepresentation } from 'src/generated-api-client';
import { taskApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

const DEFAULT_TASKS_LIST_SIZE = 100;
const DEFAULT_TASKS_PAGE_NUMBER = 0;

export class AssignedTasksStoreClass extends BasicStore<TaskRepresentation> {
    api: BasicStoreApi<TaskRepresentation> = {
        loadList: async () => {
            const resp = await RequestHelper.unwrapFromAxiosPromise(
                taskApi.listAssigned(
                    DEFAULT_TASKS_PAGE_NUMBER,
                    DEFAULT_TASKS_LIST_SIZE,
                ),
            );

            return resp.content || [];
        },
        loadItem: (id: string) => {
            return RequestHelper.unwrapFromAxiosPromise(
                taskApi.getTaskInfo(id),
            );
        },
    };

    getTaskById(id: string) {
        return this.list.find((task) => task.id === id);
    }

    @action reset() {
        this.itemLoader.reset();
        this.formLoader.reset();
    }

    formKey = '';
}

export const AssignedTasksStore = new AssignedTasksStoreClass();
