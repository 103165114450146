import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { FormioForm } from 'src/components/FormioForm/FormioForm';
import { Spinner } from 'src/components/Spinner/Spinner';
import { FormioEvent } from 'src/core/Formio.types';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { TaskRepresentation } from 'src/generated-api-client';
import { ProcessPageRouterParams } from 'src/pages/ProcessPage/ProcessPage';
import { ProcessDefinitionsStore } from 'src/stores/ProcessDefinitionsStore/ProcessDefinitionsStore';
import { ProcessStoreProvider } from 'src/stores/ProcessStore/ProcessStore';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

export type EntityProcessProps = {
    entityUrl: string;
};

export const EntityProcess = observer(({ entityUrl }: EntityProcessProps) => {
    const { params } = useRouteMatch<
        ProcessPageRouterParams & { entityId: string }
    >();

    const { t } = useTranslation();
    const processDefinition = useMemo(() => {
        return ProcessDefinitionsStore.getProcessDefinition(params.processKey);
    }, [params.processKey, ProcessDefinitionsStore.list]);

    useEffect(() => {
        ProcessDefinitionsStore.loadItem(params.processKey);
    }, [params.processKey]);

    const process = ProcessStoreProvider.getInstance(params.processKey);

    useEffect(() => {
        if (processDefinition) {
            process.loadForm(processDefinition.startFormKey);
        }

        return () => {
            process.itemLoader.reset();
            process.formLoader.reset();
        };
    }, [processDefinition]);

    const loadingStatus = useMemo(() => {
        return new CombinedLoadingStatus([
            ProcessDefinitionsStore.listLoader,
            process.itemLoader,
            process.formLoader,
        ]);
    }, [process]);

    if (loadingStatus.isLoading) {
        return <StyledSpinner />;
    }

    const hasL10nTitleKey = Boolean(
        processDefinition?.extensions?.l10nTitleKey,
    );
    const title = hasL10nTitleKey
        ? t(`${processDefinition?.extensions?.l10nTitleKey}`, {
              context: processDefinition,
          })
        : processDefinition?.name;

    return (
        <StyledWrapper>
            <StyledProcessHeader>{title}</StyledProcessHeader>
            <FormioForm
                form={(ProcessDefinitionsStore.currentItem as any)?.form as any}
                submission={process.getInitialVariables()}
                onSubmit={getSubmitHandler(params.processKey)}
                normalizeSubmission
                onCustomEvent={(event) => {
                    if (event.type === 'navigateToEntityCard') {
                        event.event.preventDefault();
                        Router.navigate(entityUrl);
                    }

                    return Promise.resolve();
                }}
            />
        </StyledWrapper>
    );
});

const StyledProcessHeader = styled.h1`
    margin-bottom: 0;

    @media (max-width: 576px) {
        font-size: 32px;
    }
`;

const submitForm = async (processKey: string, data: any) => {
    const process = ProcessStoreProvider.getInstance(processKey);
    await process.update(processKey, data);
    const tasks = process.nextTasks as any as TaskRepresentation[];
    if (tasks?.length) {
        Router.navigate(
            UrlHelper.getTargetUrl(RoutePaths.task, {
                taskId: tasks[0].id!,
            }),
        );
    } else {
        Router.navigate(RoutePaths.index);
    }
};

const getSubmitHandler = (processKey: string) => {
    let isSubmitting = false;

    return async (values: FormioEvent) => {
        if (isSubmitting) {
            return;
        }
        isSubmitting = true;
        const { data } = values;

        await submitForm(processKey, data);
    };
};

const StyledSpinner = styled(Spinner)`
    margin: 0 auto;
    display: block;
`;

const StyledWrapper = styled.div`
    .datagrid-table {
        border: none !important;
    }
`;
