/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Spinner } from 'src/components/Spinner/Spinner';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { IndexPageViewModel } from 'src/pages/IndexPage/IndexPageViewModel';
import { AssignedTasksStore } from 'src/stores/AssignedTasksStore/AssignedTasksStore';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { UrlHelper } from 'src/utils/UrlHelper';

const viewModel = IndexPageViewModel;

export const IndexPage = observer(() => {
    const [loaded, setLoaded] = useState(false);

    const load = async () => {
        await AssignedTasksStore.loadList();
        setLoaded(true);
    };

    useEffect(() => {
        load();
    }, []);

    if (!viewModel.loadingStatus.isLoaded || !loaded) {
        return <Spinner />;
    }

    const hasTasks = Boolean(AssignedTasksStore.list?.length);
    const hasProcess = SettingsStore.settings?.defaultProcessKey;

    if (hasTasks && !AssignedTasksStore.itemLoader.hasError) {
        return (
            <Redirect
                to={UrlHelper.getTargetUrl(RoutePaths.task, {
                    taskId: AssignedTasksStore.list![0].id!,
                })}
            />
        );
    }
    if (hasProcess) {
        return (
            <Redirect
                to={UrlHelper.getTargetUrl(RoutePaths.process, {
                    processKey: SettingsStore.settings?.defaultProcessKey!,
                })}
            />
        );
    }

    return <Redirect to={RoutePaths.applications} />;
});
