import { getFileUrl } from 'src/services/apiServices';

type BackendFile = { name: string; type: string; url?: string };
type FormioFile = Required<BackendFile>;

export const convertToFormioFileModels = (
    files: Array<BackendFile>,
): Array<FormioFile> => {
    return files.map((file) => {
        if (file.url) {
            return file as FormioFile;
        }

        return {
            ...file,
            url: getFileUrl(file.name),
        };
    });
};
