import { Utils } from 'formiojs';
import { form as template } from 'src/formio/templates/input/form';
import { getDisabledValue } from 'src/formio/utils/getDisabledValue';

const compiledForm = Utils.Evaluator.template(template, 'input');
export const formioInputComponentCustomTemplate = (ctx: any) => {
    const sliderStep = ctx?.instance?.interpolate(ctx?.component?.sliderStep, {
        data: ctx?.data,
        row: ctx?.row,
    });
    const sliderMin = ctx?.instance?.interpolate(ctx?.component?.min, {
        data: ctx?.data,
        row: ctx?.row,
    });
    const sliderMax = ctx?.instance?.interpolate(ctx?.component?.max, {
        data: ctx?.data,
        row: ctx?.row,
    });

    return compiledForm({
        ...ctx,
        disabledValue: getDisabledValue(ctx),
        sliderStep,
        sliderMax,
        sliderMin,
    });
};
