import { useEffect } from 'react';

type JumioPayloadValue = 'success' | 'error' | 'loaded';
const payloadValuesToSubmit: Array<JumioPayloadValue> = ['error', 'success'];

export const useJumio = (formReference: React.MutableRefObject<any>) => {
    const onMessageHandler = (e: MessageEvent<any>) => {
        const jumioMessage = parseJumioMessage(e.data);

        if (
            isJumioMessage(jumioMessage) &&
            payloadValuesToSubmit.includes(jumioMessage.payload.value)
        ) {
            formReference.current?.formio?.submit();
        }
    };
    useEffect(() => {
        window.addEventListener('message', onMessageHandler);

        return () => {
            window.removeEventListener('message', onMessageHandler);
        };
    }, []);
};

const payloadValues: Array<JumioPayloadValue> = ['loaded', 'success'];
const isJumioMessage = (
    message?: Record<string, any> | null,
): message is JumioDataMessage => {
    if (
        message &&
        message.eventType === 510 &&
        message.payload &&
        payloadValues.includes(message.payload.value)
    ) {
        return true;
    }

    return false;
};

type JumioDataMessage = {
    authorizationToken: string;
    eventType: 510;
    dateTime: string;
    payload: { value: JumioPayloadValue; metainfo: any };
    workflowExecutionId: string;
    accountId: string;
    customerInternalReference: string;
};

const parseJumioMessage = (data: string): Record<string, any> | null => {
    try {
        return JSON.parse(data);
    } catch (error) {
        return null;
    }
};
