import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BasePage } from 'src/components/BasePage';
import { Empty } from 'src/components/Empty/Empty';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { AssignedTasksStore } from 'src/stores/AssignedTasksStore/AssignedTasksStore';
import { DateHelper } from 'src/utils/DateHelper';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

export const TasksPage = observer(() => {
    const { t } = useTranslation();

    useEffect(() => {
        AssignedTasksStore.loadList();
    }, []);

    return (
        <BasePage
            pageName="activity"
            isLoading={AssignedTasksStore.listLoader.isLoading}
            title={t('Page.Activity.Title')}
        >
            <List
                dataSource={AssignedTasksStore.list}
                locale={{
                    emptyText: <Empty />,
                }}
                renderItem={(item) => {
                    const hasL10nTitleKey = Boolean(
                        item.extensions?.l10nTitleKey,
                    );

                    const title = hasL10nTitleKey
                        ? t(`${item.extensions?.l10nTitleKey}`, {
                              ...item.taskInfo,
                          })
                        : item.name;

                    return (
                        <StyledLink
                            to={UrlHelper.getTargetUrl(RoutePaths.task, {
                                taskId: item.id!,
                            })}
                        >
                            <StyledName>{title}</StyledName>
                            <StyledCreatedAt>
                                {DateHelper.format(item.createTime)}
                            </StyledCreatedAt>
                        </StyledLink>
                    );
                }}
            />
        </BasePage>
    );
});

const StyledLink = styled(Link)`
    background: var(--item-list-background);
    padding: 30px;
    display: block;
    color: #0d0f1c;
    &:hover {
        color: #0d0f1c;
        text-decoration: none;
        -webkit-box-shadow: 0px 2px 24px -22px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 2px 24px -22px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 2px 24px -22px rgba(0, 0, 0, 0.75);
    }
    border-radius: 6px;
    margin-bottom: 29px;

    @media (max-width: 576px) {
        padding: 25px 15px;
    }
`;

const StyledName = styled.div`
    font-weight: 600;
    font-size: 30px;
    line-height: 105%;
    letter-spacing: -0.03em;
    color: var(--form-text-color);

    @media (max-width: 576px) {
        font-size: 16px;
    }
`;

const StyledCreatedAt = styled.div`
    font-size: 16px;
    line-height: 165%;

    color: #828b94;
    margin-top: 12px;

    @media (max-width: 576px) {
        font-size: 11px;
    }
`;
