/* eslint-disable sonarjs/cognitive-complexity */
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useRouteMatch } from 'react-router-dom';
import { FormioForm } from 'src/components/FormioForm/FormioForm';
import { formioCustomEventHandler } from 'src/utils/formio/customEventHandler';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';

export type EntityDetailsProps = {
    store: BasicStore<any>;
};
export const EntityDetails = observer(({ store }: EntityDetailsProps) => {
    const { url } = useRouteMatch<{ entityId: string }>();

    return (
        <FormioForm
            form={store.currentItemForm as any}
            submission={{
                data: _.cloneDeep(store.currentItem),
            }}
            onSubmit={() => {
                return Promise.resolve();
            }}
            onCustomEvent={(event) => {
                formioCustomEventHandler(event, url);

                return Promise.resolve();
            }}
        />
    );
});
