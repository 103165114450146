import React from 'react';
import { Router } from 'src/core/router/Router';

export const FormioDOMEventHandlersMap: Record<
    string,
    (e: React.MouseEvent<any>) => void
> = {
    click: (e: React.MouseEvent<HTMLHRElement>) => {
        const a = (e.target as any)?.closest('.internal-link');
        if (a && a.getAttribute('href')) {
            e.preventDefault();
            Router.navigate(a.getAttribute('href'));
        }
    },
};

export const getFormioDOMEventHandler = (type: string) => {
    return FormioDOMEventHandlersMap[type];
};
