/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import { FormioFormProps } from 'src/components/FormioForm/FormioFormProps';
import { FormioCustomEvent } from 'src/core/Formio.types';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { ProcessDefinitionsStore } from 'src/stores/ProcessDefinitionsStore/ProcessDefinitionsStore';
import { ProcessStoreProvider } from 'src/stores/ProcessStore/ProcessStore';
import { UrlHelper } from 'src/utils/UrlHelper';

export const getCustomEventHandler = (type: string) => {
    return CUSTOM_EVENT_HANDLERS[type];
};

export type CustomEventHandler = (
    event: FormioCustomEvent<any>,
    formioFormProps: FormioFormProps<any>,
) => Promise<void>;

const CUSTOM_EVENT_HANDLERS: Record<string, CustomEventHandler> = {
    submitWithoutData: async (event, { onSubmit }) => {
        if (onSubmit) {
            const data = _.pick(event.data, [event.component.key]);
            await onSubmit(data);
        }
    },

    startProcess: async (event, { submission }) => {
        const { processKey } = event.component.properties;
        if (!processKey) {
            throw new Error(
                "Application can't start process without process key",
            );
        }

        const process = ProcessStoreProvider.getInstance(processKey);
        await process.loadForm(processKey);
        // if (process.currentItemForm) {
        //     return Router.navigate(
        //         UrlHelper.getTargetUrl(RoutePaths.process, { processKey }),
        //         submission?.data,
        //     );
        // }
        // await process.loadItem(processKey);
        // process.setContext(submission?.data);
        // await process.submit();
        // if (process.nextTasks && process.nextTasks[0]) {
        //     return Router.navigate(
        //         UrlHelper.getTargetUrl(RoutePaths.task, {
        //             taskId: process.nextTasks[0].id,
        //         }),
        //         submission?.data,
        //     );
        // }
    },
};
