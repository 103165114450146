import _ from 'lodash';
import { computed } from 'mobx';
import { ProcessDefinitionRepresentation } from 'src/generated-api-client';
import { processApi } from 'src/services/apiServices';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

type UiAreasTypes = 'mainMenu' | 'accountManagement' | undefined;

const uiSettingsStore = SettingsStore;

const filterProcessByBorrowerType = (
    process: ProcessDefinitionRepresentation,
) => {
    const processBorrowerType = process?.extensions?.borrowerTypes as any;

    return processBorrowerType
        ? _.intersection(
              uiSettingsStore.currentBorrowerTypes,
              processBorrowerType?.split(','),
          ).length !== 0
        : true;
};
export class ProcessDefinitionsStoreClass extends BasicStore<ProcessDefinitionRepresentation> {
    api: BasicStoreApi<ProcessDefinitionRepresentation> = {
        loadList: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                processApi.listStartableByUser(),
            );
        },

        loadItem: (key: string): Promise<any> => {
            return RequestHelper.unwrapFromAxiosPromise(
                processApi.getStartFormWithData(key),
            );
        },
    };

    getProcessDefinition(key: string) {
        return this.list.find(
            (processDefinition) => processDefinition.key === key,
        );
    }

    @computed get mainMenuProcesses() {
        return this.list
            .filter(
                (process) =>
                    (process?.extensions?.uiAreas as UiAreasTypes) ===
                    'mainMenu',
            )
            .filter(filterProcessByBorrowerType);
    }

    @computed get accountManagementProcesses() {
        return this.list
            .filter(
                (process) =>
                    (process?.extensions?.uiAreas as UiAreasTypes) ===
                    'accountManagement',
            )
            .filter(filterProcessByBorrowerType);
    }
}

export const ProcessDefinitionsStore = new ProcessDefinitionsStoreClass();
