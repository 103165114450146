import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BasePage, PageType } from 'src/components/BasePage';
import { FormioForm } from 'src/components/FormioForm/FormioForm';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';
import { ProfileStore } from 'src/stores/ProfileStore/ProfileStore';

export const ProfilePage = observer(() => {
    useEffect(() => {
        ProfileStore.loadItem(1);
        ProfileStore.loadForm();
        () => {
            ApplicationsStore.itemLoader.reset();
            ApplicationsStore.formLoader.reset();
        };
    }, [1]);

    const pageIsLoading =
        ProfileStore.itemLoader.isLoading || ProfileStore.formLoader.isLoading;

    return (
        <BasePage
            pageName={`profile-${1}`}
            isLoading={pageIsLoading}
            type={PageType.SIMPLE_FORM}
        >
            <FormioForm
                form={ProfileStore.currentItemForm as any}
                submission={{
                    data: _.cloneDeep(ProfileStore.currentItem),
                }}
                onSubmit={() => {
                    return Promise.resolve();
                }}
                onCustomEvent={() => {
                    return Promise.resolve();
                }}
            />
        </BasePage>
    );
});
