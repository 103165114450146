import 'antd/dist/antd.css';
import { observer } from 'mobx-react-lite';
import { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'src/App.css';
import { SplashScreen } from 'src/components/SplashScreen/SplashScreen';
import { AppLayout } from 'src/containers/AppLayout/AppLayout';
import { BrowserRouterBehavior } from 'src/core/router/BrowserRouterBehavior';
import 'src/formioSettings';
import 'src/services/api.config';
import 'src/services/apiInterceptors';
import { AppStore } from 'src/stores/AppStore/AppStore';

const App = observer(() => {
    useEffect(() => {
        AppStore.init();
    }, []);

    return (
        <Suspense fallback={<SplashScreen />}>
            <BrowserRouter>
                <BrowserRouterBehavior />
                <AppLayout />
            </BrowserRouter>
        </Suspense>
    );
});

export default App;
