export const form = `
{% if (ctx.totalPages > 1) { %}
{% if (!ctx.component.isStartPageZero) { %}
<div class="pagination-container">

{% if (ctx.component.showNextPrevBtns) { %}
<button ref="previousPageButton" class="pagination-btn prev-btn" {% if (ctx.currentPage === 1) { %}disabled{% } %}>
<img class="pagination-btn-arrow" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAQAAAAnOwc2AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAACYktHRAD/h4/MvwAAAAlwSFlzAAAPrwAAD68B1qQibQAAAAd0SU1FB+cJFgc7LLTSfvYAAABGSURBVAjXY2BAAAGG1QxbGNiRRBj4GGYwvGNIRhWaxfCeIZWBEVloJsN7hlRkVcwMfQxfGdIQAkwMOAEW7TgswuEkFMcDANr9ELlNZNysAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTA5LTIyVDA3OjU5OjM5KzAwOjAwUx0gKwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wOS0yMlQwNzo1OTozOSswMDowMCJAmJcAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMDktMjJUMDc6NTk6NDQrMDA6MDAeR9GRAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==" />
</button>
{% } %}

<div class="page-selection">
    {% if (ctx.totalPages <= 7) { %}
        {% for (var i = 1; i <= ctx.totalPages; i++) { %}
            <button ref="buttonPage" data-page-number="{{i}}" class="pagination-btn page-btn {% if (ctx.currentPage === i) { %}active{% } %}">
                {{ i }}
            </button>
        {% } %}
    {% } else { %}
        {% if (ctx.currentPage <= 4) { %}
            {% for (var i = 1; i <= 5; i++) { %}
                <button ref="buttonPage" data-page-number="{{i}}" class="pagination-btn page-btn {% if (ctx.currentPage === i) { %}active{% } %}">
                    {{ i }}
                </button>
            {% } %}
            <span class="pagination-btn">...</span>
            <button ref="buttonPage" data-page-number="{{ctx.totalPages}}" class="pagination-btn page-btn">
                {{ ctx.totalPages }}
            </button>
        {% } else if (ctx.currentPage >= ctx.totalPages - 3) { %}
            <button ref="buttonPage" data-page-number="1" class="pagination-btn page-btn">
                1
            </button>
            <span class="pagination-btn">...</span>
            {% for (var i = ctx.totalPages - 4; i <= ctx.totalPages; i++) { %}
                <button ref="buttonPage" data-page-number="{{i}}" class="pagination-btn page-btn {% if (ctx.currentPage === i) { %}active{% } %}">
                    {{ i }}
                </button>
            {% } %}
        {% } else { %}
            <button ref="buttonPage" data-page-number="1" class="pagination-btn page-btn">
                1
            </button>
            <span class="pagination-btn">...</span>
            {% for (var i = ctx.currentPage - 1; i <= ctx.currentPage + 1; i++) { %}
                <button ref="buttonPage" data-page-number="{{i}}" class="pagination-btn page-btn {% if (ctx.currentPage === i) { %}active{% } %}">
                    {{ i }}
                </button>
            {% } %}
            <span class="pagination-btn">...</span>
            <button ref="buttonPage" data-page-number="{{ctx.totalPages}}" class="pagination-btn page-btn">
                {{ ctx.totalPages }}
            </button>
        {% } %}
    {% } %}
</div>


{% if (ctx.component.showNextPrevBtns) { %}
<button ref="nextPageButton" class="pagination-btn next-btn" {% if (ctx.currentPage === ctx.totalPages) { %}disabled{% } %}>
 <img class="pagination-btn-arrow" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAQAAAAnOwc2AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAACYktHRAD/h4/MvwAAAAlwSFlzAAAPrwAAD68B1qQibQAAAAd0SU1FB+cIGxEQAsnVoP8AAABRSURBVAjXfc4xDkRQAATQxy9EI1rnE9xma7tX0DqBA1EpFBKdWF+8cpLJDGRGg9IpYJfrVCabi0Rj8VO4aSy+cdxafQRSr6L641Bt1v9H0fkD3SESBsUobhUAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMDgtMjdUMTc6MTU6NTIrMDA6MDANJFJiAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIzLTA4LTI3VDE3OjE1OjUyKzAwOjAwfHnq3gAAACh0RVh0ZGF0ZTp0aW1lc3RhbXAAMjAyMy0wOC0yN1QxNzoxNjowMiswMDowMIi7fmYAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC" />
</button>


{% if (!ctx.component.showItemsPerPageDropdown) { %}
<div class="page-size-dropdown">
  <input type="checkbox" id="dropdown-toggle">
  <label for="dropdown-toggle" class="dropdown-button">{{ctx.instance.getValue()?.itemsPerPage}} &#9660;</label>
  <div class="dropdown-content">
    <div ref="sizeBtn" class="dropdown-option" data-items-per-page="10">10</div>
    <div ref="sizeBtn" class="dropdown-option" data-items-per-page="20">20</div>
    <div ref="sizeBtn" class="dropdown-option" data-items-per-page="30">30</div>
  </div>
</div>
{% } %}
</div>
{% } %}

{% } else { %}

<div class="pagination-container">
{% if (ctx.component.showNextPrevBtns) { %}
<button ref="previousPageButton" class="pagination-btn prev-btn" {% if (ctx.currentPage === 0) { %}disabled{% } %}>
<img class="pagination-btn-arrow" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAQAAAAnOwc2AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAACYktHRAD/h4/MvwAAAAlwSFlzAAAPrwAAD68B1qQibQAAAAd0SU1FB+cJFgc7LLTSfvYAAABGSURBVAjXY2BAAAGG1QxbGNiRRBj4GGYwvGNIRhWaxfCeIZWBEVloJsN7hlRkVcwMfQxfGdIQAkwMOAEW7TgswuEkFMcDANr9ELlNZNysAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTA5LTIyVDA3OjU5OjM5KzAwOjAwUx0gKwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wOS0yMlQwNzo1OTozOSswMDowMCJAmJcAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMDktMjJUMDc6NTk6NDQrMDA6MDAeR9GRAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==" />
</button>
{% } %}

<div class="page-selection">
    {% if (ctx.totalPages <= 7) { %}
        {% for (var i = 0; i <= ctx.totalPages; i++) { %}
            <button ref="buttonPage" data-page-number="{{i}}" class="pagination-btn page-btn {% if (ctx.currentPage === i) { %}active{% } %}">
                {{ i + 1 }}
            </button>
        {% } %}
    {% } else { %}
        {% if (ctx.currentPage <= 4) { %}
            {% for (var i = 0; i <= 5; i++) { %}
                <button ref="buttonPage" data-page-number="{{i}}" class="pagination-btn page-btn {% if (ctx.currentPage === i) { %}active{% } %}">
                    {{ i + 1 }}
                </button>
            {% } %}
            <span class="pagination-btn">...</span>
            <button ref="buttonPage" data-page-number="{{ctx.totalPages}}" class="pagination-btn page-btn">
                {{ ctx.totalPages }}
            </button>
        {% } else if (ctx.currentPage >= ctx.totalPages - 3) { %}
            <button ref="buttonPage" data-page-number="0" class="pagination-btn page-btn">
                1
            </button>
            <span class="pagination-btn">...</span>
            {% for (var i = ctx.totalPages - 4; i <= ctx.totalPages; i++) { %}
                <button ref="buttonPage" data-page-number="{{i}}" class="pagination-btn page-btn {% if (ctx.currentPage === i) { %}active{% } %}">
                    {{ i + 1 }}
                </button>
            {% } %}
        {% } else { %}
            <button ref="buttonPage" data-page-number="0" class="pagination-btn page-btn">
                1
            </button>
            <span class="pagination-btn">...</span>
            {% for (var i = ctx.currentPage - 1; i <= ctx.currentPage + 1; i++) { %}
                <button ref="buttonPage" data-page-number="{{i}}" class="pagination-btn page-btn {% if (ctx.currentPage === i) { %}active{% } %}">
                    {{ i + 1 }}
                </button>
            {% } %}
            <span class="pagination-btn">...</span>
            <button ref="buttonPage" data-page-number="{{ctx.totalPages}}" class="pagination-btn page-btn">
                {{ ctx.totalPages + 1 }}
            </button>
        {% } %}
    {% } %}
</div>
{% if (ctx.component.showNextPrevBtns) { %}
<button ref="nextPageButton" class="pagination-btn next-btn" {% if (ctx.currentPage === ctx.totalPages) { %}disabled{% } %}>
 <img class="pagination-btn-arrow" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAQAAAAnOwc2AAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAACYktHRAD/h4/MvwAAAAlwSFlzAAAPrwAAD68B1qQibQAAAAd0SU1FB+cIGxEQAsnVoP8AAABRSURBVAjXfc4xDkRQAATQxy9EI1rnE9xma7tX0DqBA1EpFBKdWF+8cpLJDGRGg9IpYJfrVCabi0Rj8VO4aSy+cdxafQRSr6L641Bt1v9H0fkD3SESBsUobhUAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMDgtMjdUMTc6MTU6NTIrMDA6MDANJFJiAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIzLTA4LTI3VDE3OjE1OjUyKzAwOjAwfHnq3gAAACh0RVh0ZGF0ZTp0aW1lc3RhbXAAMjAyMy0wOC0yN1QxNzoxNjowMiswMDowMIi7fmYAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC" />
</button>
{% } %}


{% if (!ctx.component.showItemsPerPageDropdown) { %}
<div class="page-size-dropdown">
  <input type="checkbox" id="dropdown-toggle">
  <label for="dropdown-toggle" class="dropdown-button">{{ctx.instance.getValue()?.itemsPerPage}} &#9660;</label>
  <div class="dropdown-content">
    <div ref="sizeBtn" class="dropdown-option" data-items-per-page="10">10</div>
    <div ref="sizeBtn" class="dropdown-option" data-items-per-page="20">20</div>
    <div ref="sizeBtn" class="dropdown-option" data-items-per-page="30">30</div>
  </div>
</div>
{% } %}
{% } %}
{% } %}
`;
