export const CssVariables = {
    headerHeight: '52px',
    headerMargin: '52px',
    footerHeight: '50px',

    masterDetailsAsideWidth: '426px',
};

export const CssColors = {
    black: 'black',
};
