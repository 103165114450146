import Components from 'formiojs/components/Components';

const TextfieldComponent = (Components as any).components.textfield;

class TextField extends (TextfieldComponent as any) {
    get prefix() {
        const parentPrefix = super.prefix;

        return parentPrefix
            ? this.interpolate(parentPrefix, {
                  data: this?.root?.data,
                  row: this?.root?.data,
              })
            : '';
    }

    get suffix() {
        const parentSuffix = super.suffix;

        return parentSuffix
            ? this.interpolate(parentSuffix, {
                  data: this?.root?.data,
                  row: this?.root?.data,
              })
            : '';
    }

    setValue(value: any, flags: any = {}) {
        this.redraw();

        return super.setValue(value, flags);
    }

    attach(element: any) {
        super.attach(element);

        if (this.element) {
            const input = this.element.querySelectorAll('input');
            input[0]?.setAttribute('autocomplete', 'new-password');
        }
    }
}

export default TextField;
