import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ThemeStore } from 'src/stores/ThemeStore/ThemeStore';
import styled from 'styled-components';

export type StatusInfo = {
    color: string;
    borderColor: string;
    backgroundColor: string;
    title: React.ReactNode;
};

export const DEFAULT_STATUS_KEY = 'Default';

export type EntityStatusProps = {
    statusInfoMap: Record<string, StatusInfo>;
    status?: string;
    filled?: boolean;
    entityName: string;
};

export const EntityStatus = observer(
    ({ statusInfoMap, status, filled, entityName }: EntityStatusProps) => {
        const statusInfo = getStatusInfo(statusInfoMap, status);
        const { t } = useTranslation();

        return (
            <StyledStatus
                statusInfo={statusInfo}
                fill={filled || ThemeStore.getThemeVariables.isFilledStatus}
            >
                {t(`data:status.${entityName}.${status}`)}
            </StyledStatus>
        );
    },
);

type StyledStatusProps = { statusInfo: StatusInfo; fill?: boolean };
const StyledStatus = styled.div<StyledStatusProps>`
    font-size: 16px;
    line-height: 165%;
    text-align: center;
    color: #000000;
    color: ${(props) => props.statusInfo.color};

    border: 1px solid #c5c5c5;
    border-color: ${(props) => props.statusInfo.borderColor};
    ${(props) =>
        props.fill
            ? `background-color: ${props.statusInfo.backgroundColor};`
            : ''}
    border-radius: 48px;

    padding: 3px 15px;

    white-space: nowrap;
`;

export const getStatusInfo = (
    map: Record<string, StatusInfo>,
    status?: string,
): StatusInfo => {
    if (status && map[status]) {
        return map[status];
    }

    return { ...map[DEFAULT_STATUS_KEY], title: status };
};
