export const CssVariablesTheme = {
    // Header
    headerColor: 'gray',
    headerBackground: 'transparent',
    headerApplyBtnBg: '#0a65ed',
    headerApplyBtnColor: '#ffffff',
    headerFontSize: '24px',
    headerActiveLinkColor: '#ffffff',
    logoMaxSize: '0%',

    /* Body*/
    formTextColor: '#ffffff',
    background:
        'linear-gradient(45deg, rgba(33,45,59,1) 0%, rgba(10,12,14,1) 46%, rgba(33,45,59,1) 92%)',
    font: 'SF Pro Text',
    fontSize: '16px',
    itemListBg: '#EFF1FF12',
    basePageWidth: '70%',
    basePageMaxWidth: 'FULL',

    /* Form*/
    labelColor: 'gray',
    inputBorderColor: '#EFF1FF12',
    inputBg: '#EFF1FF12',
    inputBorderColorHover: '#1367E1',
    inputBorderRadius: '8px',
    formButtonsBg: '#0041E4',
    tabsColor:
        'linear-gradient(90deg, rgba(164,252,183,1) 0%, rgba(73,177,235,1) 100%)',
    calculatorSecondaryText: 'gray',
    calculatorSliderTrack: '#0a65ed',
    calculatorInputColor: '#ffffff',
    calculatorSliderHandleBg: '#49b1eb',
    calculatorSliderHandleBorder: '#49b1eb',
    tabsBorderBottomColor: 'transparent',
    activeTabColor: '#ffffff',
    activeTabBorderColor: 'transparent',

    /* Footer*/
    footerBg: 'transparent',
    footerTextColor: 'gray',

    /* Statuses color */
    pending: {
        color: '#ffffff',
        borderColor: '#69329F',
        backgroundColor: '#69329F',
    },
    active: {
        color: '#ffffff',
        borderColor: '#0BB627',
        backgroundColor: '#0BB627',
    },
    repaid: {
        color: '#ffffff',
        borderColor: '#A02020',
        backgroundColor: '#A02020',
    },
    overdue: {
        color: '#ffffff',
        borderColor: '#A02020',
        backgroundColor: '#A02020',
    },
    writtenOff: {
        color: '#ffffff',
        borderColor: '#404040',
        backgroundColor: '#404040',
    },
    processes: {
        color: '#ffffff',
        borderColor: '#E5DF3F',
        backgroundColor: '#E5DF3F',
    },
    error: {
        color: '#ffffff',
        borderColor: '#A02020',
        backgroundColor: '#A02020',
    },
    onKyc: {
        color: '#ffffff',
        borderColor: '#69329F',
        backgroundColor: '#69329F',
    },
    verification: {
        color: '#ffffff',
        borderColor: '#69329F',
        backgroundColor: '#69329F',
    },
    updateRequested: {
        color: '#ffffff',
        borderColor: '#A02020',
        backgroundColor: '#A02020',
    },
    underwriting: {
        color: '#ffffff',
        borderColor: '#69329F',
        backgroundColor: '#69329F',
    },
    declined: {
        color: '#ffffff',
        borderColor: '#A02020',
        backgroundColor: '#A02020',
    },
    canceled: {
        color: '#ffffff',
        borderColor: '#A02020',
        backgroundColor: '#A02020',
    },
    approved: {
        color: '#ffffff',
        borderColor: '#e5813f',
        backgroundColor: '#e5813f',
    },
    disbursed: {
        color: '#ffffff',
        borderColor: '#0BB627',
        backgroundColor: '#0BB627',
    },

    isFilledStatus: true,
};
