import { observer } from 'mobx-react-lite';
import { useRouteMatch } from 'react-router-dom';
import { BasePage, PageType } from 'src/components/BasePage';
import { TaskForm } from 'src/components/TaskForm';

type TaskPageRouterParams = {
    taskId: string;
};
export const TaskPage = observer(() => {
    const { params } = useRouteMatch<TaskPageRouterParams>();

    return (
        <BasePage
            pageName="task"
            type={PageType.WIDE_FORM}
            selectorPageName="calculator"
        >
            <TaskForm taskId={params.taskId} />
        </BasePage>
    );
});
