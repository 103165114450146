import { observer } from 'mobx-react-lite';
import { Route, Switch } from 'react-router-dom';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { IndexPage } from 'src/pages/IndexPage/IndexPage';
import { ProcessPage } from 'src/pages/ProcessPage/ProcessPage';
import { RouterStore } from 'src/stores/RouterStore.ts/RouterStore';

export const RoutesLayout = observer(() => {
    return (
        <Switch>
            <Route path={RoutePaths.index} exact component={IndexPage} />
            <Route path={RoutePaths.process} component={ProcessPage} />
            {RouterStore.routes.map((route) => {
                return (
                    <Route
                        path={route.path}
                        exact={route.exact !== false}
                        component={route.Component}
                    />
                );
            })}
            <Route path="*" />
        </Switch>
    );
});
