import { useEffect } from 'react';

export const useAutoSubmitFormioIframe = (
    formReference: React.MutableRefObject<any>,
) => {
    const onMessageHandler = (e: MessageEvent<any>) => {
        const postMessage = parsePostMessage(e.data);

        if (
            isAutoSubmitFormioMessage(postMessage) &&
            formReference.current?.formio
        ) {
            // eslint-disable-next-line no-param-reassign
            formReference.current.formio.data = {
                ...formReference.current?.formio?.data,
                ...postMessage.payload,
            };
            formReference.current.formio?.submit();
        }
    };
    useEffect(() => {
        window.addEventListener('message', onMessageHandler);

        return () => {
            window.removeEventListener('message', onMessageHandler);
        };
    }, []);
};

const AUTO_SUBMIT_EVENT_TYPE = 'auto-submit-formio';
const isAutoSubmitFormioMessage = (
    message?: Record<string, any> | null,
): message is AutoSubmitFormioMessage => {
    if (message && message.type === AUTO_SUBMIT_EVENT_TYPE) {
        return true;
    }

    return false;
};

type AutoSubmitFormioMessage = {
    type: string;
    payload: any;
    createdAt: string;
};

const parsePostMessage = (data: string): Record<string, any> | null => {
    try {
        return JSON.parse(data);
    } catch (error) {
        return null;
    }
};
