/* eslint-disable max-lines */
import {
    CloseOutlined,
    DownloadOutlined,
    LeftOutlined,
    RightOutlined,
} from '@ant-design/icons';
import DocViewer, { IDocument } from '@cyntler/react-doc-viewer';
import { Button } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { AnyFileRenderer } from 'src/components/Preview/AnyFileRenderer';
import { ImageRenderer } from 'src/components/Preview/ImageRenderer';
import { PDFRenderer } from 'src/components/Preview/PDFRenderer';
import { PreviewController } from 'src/components/Preview/PreviewController';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

const wrap = (min: number, max: number, index: number) => {
    if (min > index) {
        return min;
    }

    if (max < index) {
        return max;
    }

    return index;
};

const variants = {
    enter: (direction: number) => {
        return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0,
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
    },
    exit: (direction: number) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0,
        };
    },
};

type PreviewProps = {
    hideNavigateButtons?: boolean;
};

export const Preview = observer(({ hideNavigateButtons }: PreviewProps) => {
    const [[page, direction], setPage] = useState([
        PreviewController.startIndex,
        0,
    ]);

    useEffect(() => {
        setPage([PreviewController.startIndex, 0]);
    }, [PreviewController.startIndex]);

    const imageIndex = wrap(0, PreviewController.documentsCount - 1, page);

    const paginate = (newDirection: number) => {
        const newPage = page + newDirection;
        if (newPage > -1 && newPage < PreviewController.documentsCount) {
            setPage([page + newDirection, newDirection]);
        }
    };

    const source = PreviewController.documents?.[imageIndex];

    if (!PreviewController.isOpen || !source) {
        return null;
    }

    return (
        <PreviewWrapper className="file-preview">
            <PreviewWrapperHeader className="file-preview__header">
                <HeaderFileName>{source.originalName}</HeaderFileName>
                <div>
                    <StyledButton
                        onClick={(e) => {
                            const name = source.originalName || source.name;
                            if (source.uri && name) {
                                UrlHelper.downloadFile(source.uri, name);
                            }

                            (e.target as HTMLButtonElement)?.blur();
                        }}
                    >
                        <DownloadOutlined />
                    </StyledButton>
                    <StyledButton
                        onClick={() => {
                            PreviewController.close();
                        }}
                    >
                        <CloseOutlined />
                    </StyledButton>
                </div>
            </PreviewWrapperHeader>
            <PreviewWrapperBody className="file-preview__body">
                <AnimatePresence initial={false} custom={direction}>
                    <StyledMotionDiv
                        key={page}
                        custom={direction}
                        variants={variants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        transition={{
                            x: { type: 'spring', stiffness: 300, damping: 30 },
                            opacity: { duration: 0.2 },
                        }}
                    >
                        <StyledDocViewer
                            pluginRenderers={[PDFRenderer, ImageRenderer]}
                            documents={[source as IDocument]}
                            config={{
                                header: {
                                    disableHeader: false,
                                    disableFileName: false,
                                    retainURLParams: false,
                                },
                                noRenderer: {
                                    overrideComponent: AnyFileRenderer,
                                },
                            }}
                        />
                    </StyledMotionDiv>
                </AnimatePresence>
            </PreviewWrapperBody>
            {!hideNavigateButtons && (
                <>
                    <div
                        className="file-preview-nav-btn next"
                        onClick={() => paginate(1)}
                    >
                        <RightOutlined />
                    </div>
                    <div
                        className="file-preview-nav-btn prev"
                        onClick={() => paginate(-1)}
                    >
                        <LeftOutlined />
                    </div>
                </>
            )}
        </PreviewWrapper>
    );
});

const StyledMotionDiv = styled(motion.div)`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const StyledDocViewer = styled(DocViewer)`
    &#react-doc-viewer {
        margin: 0 auto;
        width: 100%;
        background: transparent;
        display: block;
        overflow: auto;
    }

    #image-renderer {
        background: transparent;
    }

    > div:first-child {
        margin: 0 auto;
    }

    #header-bar {
        display: none;
    }

    #proxy-renderer {
        width: 100%;
        color: white;
        display: flex;
        align-items: center;
        height: 100%;
        overflow: hidden;
        position: relative;
    }

    #no-renderer-download {
        height: 44px;
        padding: 9px 12px;
        border-radius: 8px;
        min-width: 147px;
        background-color: $blue;

        &:focus,
        &:hover {
            background-color: darken($blue, 10%);
        }
    }

    #no-renderer {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
    }

    #pdf-renderer {
        background: white;
    }
`;

const PreviewWrapper = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #1e1e1ed9;
    z-index: 1000;
    display: block;
    flex-direction: column;

    .file-preview-nav-btn {
        position: absolute;
        width: 50px;
        height: 50px;
        font-size: 32px;
        color: white;

        display: flex;
        justify-content: center;
        align-items: center;
        background: #31313199;
        z-index: 1;
        cursor: pointer;

        &:hover: {
            background: #313131db;
        }
    }

    .prev {
        left: 0;
        top: calc(50% - 25px);
    }

    .next {
        right: 0;
        top: calc(50% - 25px);
    }
`;

const PreviewWrapperHeader = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    height: fit-content;
    z-index: 2;
    background-image: linear-gradient(180deg, #000000e3, transparent);
    gap: 16px;
    align-items: center;
`;

const PreviewWrapperBody = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
`;

const StyledButton = styled(Button)`
    border-radius: 0;
    background: none;
    border: none;
    color: white;
    font-size: 22px;
    height: fit-content;
    line-height: 1;

    &:hover,
    &:active,
    &:focus {
        background: #31313199;
        color: white;
    }
`;

export const HeaderFileName = styled.div`
    flex: 1;
    color: white;
    padding: 0 16px;
    font-size: 13px;
`;
