import {
    BorrowerPortalApiFactory,
    BPMSResourcesManagementApiFactory,
    Configuration,
    LoanApiFactory,
    LoanApplicationApiFactory,
    ProcessDefinitionManagerApiFactory,
    TaskManagerApiFactory,
    UISettingsApiFactory,
} from 'src/generated-api-client';

const apiConfig: Configuration | undefined = undefined;
const basePath = '';

const filesApiPath = '/api/file?';
export const getFileUrl = (filename: string) => {
    const searchParams = new URLSearchParams();
    searchParams.append('form', filename);

    return `${filesApiPath}${searchParams.toString()}`;
};

export const processApi = ProcessDefinitionManagerApiFactory(
    apiConfig,
    basePath,
);

export const taskApi = TaskManagerApiFactory(apiConfig, basePath);

export const deploymentApi = BPMSResourcesManagementApiFactory(
    apiConfig,
    basePath,
);

export const uiSettingsApi = UISettingsApiFactory(apiConfig, basePath);

export const applicationsApi = LoanApplicationApiFactory(apiConfig, basePath);
export const loansApi = LoanApiFactory(apiConfig, basePath);

export const bpmsResourcesManagerApi = BPMSResourcesManagementApiFactory(
    apiConfig,
    basePath,
);

export const borrowerApi = BorrowerPortalApiFactory(apiConfig, basePath);
