import { observable } from 'mobx';
import { AssignedTasksStore } from 'src/stores/AssignedTasksStore/AssignedTasksStore';
import { ProcessDefinitionsStore } from 'src/stores/ProcessDefinitionsStore/ProcessDefinitionsStore';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';

export class IndexPageViewModelClass {
    @observable loadingStatus = new CombinedLoadingStatus([
        AssignedTasksStore.listLoader,
        ProcessDefinitionsStore.listLoader,
    ]);
}

export const IndexPageViewModel = new IndexPageViewModelClass();
