import { action, computed, observable } from 'mobx';

export interface IDocument {
    uri: string | null;
    name: string | null;
    originalName: string | null;
}

export class PreviewControllerClass {
    @observable isOpen = true;
    @observable documents?: IDocument[];
    @observable startIndex = 0;

    @action close() {
        this.isOpen = false;
    }

    @action show(documents: IDocument[], startIndex = 0) {
        this.startIndex = startIndex;
        this.documents = documents;
        this.isOpen = true;
    }

    @computed get documentsCount() {
        return this.documents?.length || 0;
    }
}

export const PreviewController = new PreviewControllerClass();
