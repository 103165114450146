import { FormioCustomEvent } from 'src/core/Formio.types';
import { Router } from 'src/core/router/Router';
import { ProcessStoreProvider } from 'src/stores/ProcessStore/ProcessStore';

export const formioCustomEventHandler = (
    event: FormioCustomEvent,
    url?: string,
) => {
    if (
        event.type === 'startProcess' &&
        (event.component as any).properties?.processKey &&
        url
    ) {
        const processKey = (event.component as any).properties?.processKey;

        const process = ProcessStoreProvider.getInstance(processKey);

        process.setContext(event.data);

        Router.navigate(`${url}/process/${processKey}`);
    }
};
