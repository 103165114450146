import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { BasePage, PageType } from 'src/components/BasePage';
import { FormioForm } from 'src/components/FormioForm/FormioForm';
import { ApplicationsStore } from 'src/stores/ApplicationsStore/ApplicationsStore';

type ApplicationPageRouterParams = {
    applicationId: string;
};
export const ApplicationPage = observer(() => {
    const { params } = useRouteMatch<ApplicationPageRouterParams>();
    useEffect(() => {
        ApplicationsStore.loadItem(params.applicationId);
        ApplicationsStore.loadForm();
        () => {
            ApplicationsStore.itemLoader.reset();
            ApplicationsStore.formLoader.reset();
        };
    }, [params.applicationId]);

    const pageIsLoading =
        ApplicationsStore.itemLoader.isLoading ||
        ApplicationsStore.formLoader.isLoading;

    return (
        <BasePage
            pageName="application"
            isLoading={pageIsLoading}
            type={PageType.BASE}
        >
            <FormioForm
                form={ApplicationsStore.currentItemForm as any}
                submission={{
                    data: _.cloneDeep(ApplicationsStore.currentItem),
                }}
                onSubmit={() => {
                    return Promise.resolve();
                }}
                onCustomEvent={() => {
                    return Promise.resolve();
                }}
            />
        </BasePage>
    );
});
