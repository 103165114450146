import _ from 'lodash';
import { action, computed } from 'mobx';
import { processApi } from 'src/services/apiServices';
import { EntityProvider } from 'src/utils/EntityProvider';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class ProcessStoreClass extends BasicStore<any> {
    api: BasicStoreApi<any> = {
        loadItem: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                processApi.getStartFormVariables(this.key),
            );
        },
        loadItemForm: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                processApi.getStartForm(this.key),
            );
        },
        updateItem: (key, data) => {
            return RequestHelper.unwrapFromAxiosPromise(
                processApi.submitStartFrom(String(key), data),
            );
        },
    };

    constructor(private key: string, private context?: any) {
        super();
    }

    @action async submit(data: any = {}) {
        await this.update(
            this.key,
            !this.context ? data : { ...data, context: this.context },
        );

        return this.updateItemLoader.data;
    }

    getInitialVariables() {
        const clonedProcessVariables = _.cloneDeep(this.itemLoader.data);

        return {
            data: {
                ...clonedProcessVariables,
                context: this.context,
            },
        };
    }

    @computed get nextTasks() {
        return this.updateItemLoader.data;
    }

    @action setContext(context?: any) {
        this.context = context;
    }
}

export const ProcessStoreProvider = new EntityProvider(ProcessStoreClass);
// export const ProcessStore = new ProcessStoreClass();
