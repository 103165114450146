/* eslint-disable no-underscore-dangle */
import { createNumberMask } from '@formio/text-mask-addons';
import Components from 'formiojs/components/Components';
import i18next from 'i18next';
import _ from 'lodash';
import { NumberHelper } from 'src/utils/NumberHelper';

const NumberComponent = (Components as any).components.number;

class NumberField extends (NumberComponent as any) {
    get prefix() {
        const parentPrefix = super.prefix;

        return parentPrefix
            ? this.interpolate(parentPrefix, {
                  data: this?.root?.data,
                  row: this?.row || this?.data,
              })
            : '';
    }

    get suffix() {
        const parentSuffix = super.suffix;

        return parentSuffix
            ? this.interpolate(parentSuffix, {
                  data: this?.root?.data,
                  row: this?.row || this?.data,
              })
            : '';
    }

    createNumberMask() {
        return createNumberMask({
            prefix: '',
            suffix: '',
            requireDecimal: _.get(this.component, 'requireDecimal', false),
            thousandsSeparatorSymbol: _.get(
                this.component,
                'thousandsSeparator',
                NumberHelper.getSeparatorsByLocale(i18next.language)?.thousands,
            ),
            decimalSymbol: _.get(
                this.component,
                'decimalSymbol',
                NumberHelper.getSeparatorsByLocale(i18next.language)?.decimal,
            ),
            decimalLimit: _.get(
                this.component,
                'decimalLimit',
                this.decimalLimit,
            ),
            allowNegative: _.get(this.component, 'allowNegative', true),
            allowDecimal: this.isDecimalAllowed(),
        });
    }

    setValue(value: any, flags: any) {
        this.redraw();

        return super.setValue(value, flags);
    }

    attach(element: any) {
        this.decimalSeparator = NumberHelper.getSeparatorsByLocale(
            i18next.language,
        )?.decimal;
        this.delimiter = NumberHelper.getSeparatorsByLocale(
            i18next.language,
        )?.thousands;

        return super.attach(element);
    }

    formatValue(value: string) {
        if (
            this.component.requireDecimal &&
            value &&
            !value.includes(this.decimalSeparator)
        ) {
            return `${value}${this.decimalSeparator}${_.repeat(
                '0',
                this.decimalLimit,
            )}`;
        }
        if (
            this.component.requireDecimal &&
            value &&
            value.includes(this.decimalSeparator)
        ) {
            return `${value}${_.repeat(
                '0',
                this.decimalLimit -
                    value.split(this.decimalSeparator)[1].length,
            )}`;
        }

        return value;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    addFocusBlurEvents() {}
}

export default NumberField;
