import { Router } from 'src/core/router/Router';
import { UtmParam } from 'src/generated-api-client';

const getTargetUrl = (urlTemplate: string, params: Record<string, string>) => {
    return Object.entries(params).reduce((template, [key, value]) => {
        return template.replace(`:${key}`, value);
    }, urlTemplate);
};

const urlSearchToObject = (params: string) => {
    return Object.fromEntries(new URLSearchParams(params));
};

const getParsedSearchQuery = () => {
    const searchQueries = Router.getQuerySearchParams();

    return Object.fromEntries(new URLSearchParams(searchQueries));
};

const getUTMFromUrl = (params: string): UtmParam[] => {
    const UTM_PREFIX = 'utm_';
    const arrayOfSearchParams = Object.entries(
        Object.fromEntries(new URLSearchParams(params)),
    );

    return arrayOfSearchParams?.reduce((init, [key, value]) => {
        if (key.toString()?.includes(UTM_PREFIX)) {
            return [
                ...init,
                {
                    name: key,
                    value,
                },
            ];
        }

        return init;
    }, [] as UtmParam[]);
};

const removeUTMFromUrl = () => {
    const urlSearchParams = Router.getQuerySearchParams();
    const clearUrl = urlSearchParams.replace(/(?=&|\?)utm_.*?(&|$)/gim, '');
    Router.setQuerySearchParams(clearUrl);
};

export const UrlHelper = {
    getTargetUrl,
    getUTMFromUrl,
    urlSearchToObject,
    removeUTMFromUrl,
    getParsedSearchQuery,
};
