export const sanitizeConfig = {
    sanitizeConfig: {
        allowedAttrs: [
            'ref',
            'src',
            'url',
            'data-oembed-url',
            'integrity',
            'crossorigin',
            'referrerpolicy',
        ],
        allowedTags: ['iframe', 'oembed', 'script', 'embed'],
        addTags: ['iframe', 'oembed', 'script', 'embed'],
        addAttr: [
            'url',
            'data-oembed-url',
            'ref',
            'integrity',
            'crossorigin',
            'referrerpolicy',
        ],
    },
};
