export const form = `
<div class="mb-2 d-flex flex-column align-items-end position-relative">
<button
    class="btn btn-primary btn-md"
    ref="header"
>
    <span class="mb-0 card-title {% if (ctx.component.theme && ctx.component.theme !== 'default') { %}text-light{% } %}">
        {{ctx.t(ctx.component.buttonName) || "Button"}}
    </span>
</button>
{% if (ctx.collapsed || ctx.builder) { %}
<div class="card-body position-absolute dropdownContainer" ref="{{ctx.nestedKey}}" id="{{ctx.instance.id}}-{{ctx.component.key}}" style="background: #ffffff;top: 57px;">
    {{ctx.children}}
</div>
{% } %}
</div>`;
