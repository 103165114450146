import { action, computed } from 'mobx';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import {
    BasicStoreApi,
    Entity,
} from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';

export abstract class BasicStore<
    Item extends Entity,
    Filter = any,
    ItemForUpdate = Item,
> {
    abstract api: BasicStoreApi<Item>;
    formKey?: string;
    itemPathKey?: string;
    filterCriteria?: FilterCriteria<Filter>;
    pager?: Pager;

    listLoader = new AsyncOperationWithStatus((...args: any[]) => {
        return this.api.loadList?.(...args);
    });

    itemLoader = new AsyncOperationWithStatus(async (...args: any[]) => {
        // eslint-disable-next-line no-return-await
        return await this.api.loadItem?.(...args);
    });

    formLoader = new AsyncOperationWithStatus((key: string) => {
        return this.api.loadItemForm?.(key);
    });

    updateItemLoader = new AsyncOperationWithStatus(
        (id: string | number, data: ItemForUpdate) => {
            return this.api.updateItem?.(id, data);
        },
    );

    @action async loadList() {
        this.listLoader.reset();
        await this.listLoader.call();
    }

    @action async loadItem(id: string | number) {
        this.itemLoader.reset();
        await this.itemLoader.call(id);
    }

    @action async loadForm(key?: string) {
        const formKey = key || this.formKey;
        if (!formKey) {
            return;
        }
        this.formLoader.reset();
        await this.formLoader.call(formKey);
    }

    @action async update(id: string | number, data: ItemForUpdate) {
        this.updateItemLoader.reset();
        await this.updateItemLoader.call(id, data);
    }

    @computed get list() {
        return (this.listLoader.data || []) as any as Item[];
    }

    @computed get currentItem() {
        return this.itemLoader.data;
    }

    @computed get currentItemForm() {
        return this.formLoader.data;
    }
}
