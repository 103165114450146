import { DocRenderer, DocRendererProps } from '@cyntler/react-doc-viewer';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import ViewerJs from 'viewerjs';
import 'viewerjs/dist/viewer.min.css';

export const Component: React.FC<DocRendererProps> = observer(
    ({
        // eslint-disable-next-line react/prop-types
        mainState: { currentDocument },
    }) => {
        const elementRef = useRef<HTMLImageElement>(null);
        const viewerJsInstance = useRef<ViewerJs>();
        useEffect(() => {
            if (elementRef.current) {
                viewerJsInstance.current = new ViewerJs(elementRef.current, {
                    inline: true,
                    rotatable: false,
                    fullscreen: false,
                    navbar: false,
                    toolbar: {
                        zoomIn: true,
                        zoomOut: true,
                        oneToOne: true,
                    },
                    title: false,
                    button: false,
                });
            }
        }, [elementRef.current]);

        if (!currentDocument) {
            return null;
        }

        return (
            <StyledDiv id="my-image-renderer">
                <StyledImg
                    alt={currentDocument.fileName}
                    ref={elementRef}
                    src={currentDocument.uri}
                />
            </StyledDiv>
        );
    },
) as DocRenderer;

(Component as DocRenderer).fileTypes = [
    'jpg',
    'png',
    'jpeg',
    'bnp',
    'image/jpg',
    'image/png',
    'image/jpeg',
    'image/bmp',
];
(Component as DocRenderer).weight = 1;

export const ImageRenderer = Component as DocRenderer;

const StyledDiv = styled.div`
    height: 100%;
    position: relative;
    overflow: auto;
    margin: 0 auto;
    padding: 50px;
    width: 100%;

    .viewer-backdrop {
        background: transparent;
    }

    .viewer-toolbar > ul > li {
        width: 42px;
        height: 42px;
        border-radius: 0;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;

        &:focus {
            box-shadow: none;
        }

        & + li {
            margin-left: 0;
        }
    }
`;

const StyledImg = styled.img`
    width: 0px;
    height: 0px;
    opacity: 0;
`;
