import Components from 'formiojs/components/Components';
import Validator from 'formiojs/validator/Validator';

const FiledComponent = (Components as any).components.file;

class File extends (FiledComponent as any) {
    attach(element: any) {
        this.loadRefs(element, {
            fileDrop: 'single',
            fileBrowse: 'single',
            galleryButton: 'single',
            cameraButton: 'single',
            takePictureButton: 'single',
            toggleCameraMode: 'single',
            videoPlayer: 'single',
            fileLink: 'multiple',
            removeLink: 'multiple',
            fileStatusRemove: 'multiple',
            fileImage: 'multiple',
            fileType: 'multiple',
            fileProcessingLoader: 'single',
        });

        this.refs?.removeLink?.forEach((removeLink: any, index: number) => {
            this.addEventListener(removeLink, 'click', (event: any) => {
                event.preventDefault();
                event.stopPropagation();
                const fileInfo = this.dataValue[index];

                this.deleteFile(fileInfo);
                this.splice(index);
                this.redraw();
            });
        });

        this.refs.fileStatusRemove.forEach(
            (fileStatusRemove: any, index: number) => {
                this.addEventListener(
                    fileStatusRemove,
                    'click',
                    (event: any) => {
                        event.preventDefault();
                        event.stopPropagation();
                        if (this.abortUpload) {
                            this.abortUpload();
                        }
                        this.statuses.splice(index, 1);
                        if (!this.statuses.length) {
                            this.refs.fileDrop.removeAttribute('hidden');
                        }
                        this.redraw();
                    },
                );
            },
        );

        this.on(
            'fileUploadingEnd',
            () => {
                setTimeout(() => {
                    this.checkValidity(this.data, true, this.row);
                }, 0);
            },
            true,
        );

        return super.attach(element);
    }

    upload(files: unknown) {
        const isValid =
            Validator.checkComponent(
                this,
                this.data,
                this.row,
                true,
                false,
            )?.filter(
                (item: { context: { validator: string } }) =>
                    item?.context?.validator === 'custom',
            )?.length === 0;

        if (!isValid) {
            this.checkValidity(this.data, true, this.row);

            return;
        }

        super.upload(files);
    }
}

export default File;
