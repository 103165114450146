import { Layout, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { LanguageSwitcher } from 'src/components/LanguageSwitcher/LanguageSwitcher';
import { Logo } from 'src/components/Logo';
import { UserMenu } from 'src/components/UserMenu';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { ProcessDefinitionsStore } from 'src/stores/ProcessDefinitionsStore/ProcessDefinitionsStore';
import { RouterStore } from 'src/stores/RouterStore.ts/RouterStore';
import { CssVariables } from 'src/styles/CssVariables';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

const { Header: AntHeader } = Layout;

export const HeaderDesktop = observer(() => {
    const { t } = useTranslation();

    return (
        <StyledHeader>
            <Logo />

            <StyledMenu mode="horizontal">
                {RouterStore.menuItems.map((router) => {
                    const title = router.menuTitle?.();

                    return (
                        <Menu.Item key={router.key}>
                            <NavLink to={router.path} activeClassName="active">
                                {title}
                            </NavLink>
                        </Menu.Item>
                    );
                })}
            </StyledMenu>
            <StyledRightMenu>
                {ProcessDefinitionsStore.mainMenuProcesses?.map((process) => (
                    <Menu.Item>
                        <StyledColoredLink
                            to={UrlHelper.getTargetUrl(RoutePaths.process, {
                                processKey: process.key!,
                            })}
                            key={process.id}
                        >
                            {t(`${process.extensions?.l10nTitleKey}`)}
                        </StyledColoredLink>
                    </Menu.Item>
                ))}
            </StyledRightMenu>

            <LanguageSwitcher />
            <UserMenu />
        </StyledHeader>
    );
});

const StyledHeader = styled(AntHeader)`
    background: var(--header-background);
    display: flex;
    color: #0d0f1c;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(222, 222, 222, 0.25);
    position: sticky;
    top: 0;
    z-index: 100;
    margin-bottom: ${CssVariables.headerMargin};
`;

const StyledMenu = styled(Menu)`
    background: var(--header-background);
    font-size: var(--header-font-size);
    flex: 1;
    border-bottom: none;

    .ant-menu-title-content {
        a {
            &:hover {
                text-decoration: none;
            }
        }
    }

    &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
        padding: 0;
    }

    .ant-menu-item {
        margin-right: 42px;
        &:after {
            display: none;
        }

        + .ant-menu-item {
            margin-left: 1px;
        }

        a {
            font-size: var(--header-font-size);
            line-height: 165%;
            display: inline-block;
            border-radius: 6px;

            letter-spacing: -0.02em;

            color: var(--header-text-color);
            background-color: transparent;

            transition: background-color 0.2s, easy-in-out;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }

        a.active {
            color: var(--header-active-link-color);
        }

        a:hover {
            color: var(--header-active-link-color);
        }
    }

    .ant-menu-item-selected {
        background-color: transparent !important;
    }
`;
const StyledRightMenu = styled(StyledMenu)`
    flex: none;
    border-right: none;
    display: flex;

    .ant-menu-item {
        background-color: transparent;
        &:active,
        &:hover {
            background-color: transparent;
        }

        background-color: transparent;
        margin-right: 19px;
        padding: 0;
        margin-bottom: 0 !important;

        &:last-child {
            margin-right: 4px;
        }

        a {
            padding: 3px 17px;
            border-radius: 48px;

            background: #f6f7f8;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
    }

    a:hover {
        opacity: 0.7 !important;
    }
`;

export const StyledUserLink = styled(Link)`
    background-color: transparent !important;
`;

export const StyledColoredLink = styled(Link)`
    background-color: var(--header-apply-btn-bg) !important;
    color: var(--header-apply-btn-color) !important;
    border-color: var(--header-apply-btn-border-color) !important;
    border: 1px solid;
    padding: 6px 20px;
    margin-right: 10px;
    transition: all ease 0.2s;
`;
