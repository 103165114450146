import Cookies from 'universal-cookie';

const cookies = new Cookies();

const setItem = (key: string, data: string) => {
    try {
        cookies.set(key, data);
    } catch (error) {
        console.warn(error);
    }
};

const getItem = <T = any>(key: string) => {
    try {
        const data = cookies.get(key);
        if (data) {
            return data as T;
        }
    } catch (error) {
        console.warn(error);
    }
};

export const CookieHelper = {
    getItem,
    setItem,
};
