import { basePath } from 'src/env';

export const RoutePaths = {
    index: `${basePath}/`,
    tasks: `${basePath}/tasks`,
    taskByKey: `${basePath}/task/key/:taskKey`,
    task: `${basePath}/tasks/:taskId`,
    process: `${basePath}/process/:processKey`,
    processes: `${basePath}/processes`,
    loans: `${basePath}/loans`,
    loan: `${basePath}/loans/:loanId`,
    applications: `${basePath}/applications`,
    application: `${basePath}/applications/:applicationId`,
    profile: `${basePath}/profile`,
    styler: `${basePath}/styler`,
};
