import { DownloadOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import classNames from 'classnames';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { IDocument } from 'react-doc-viewer';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

export const AnyFileRenderer: React.FC<{
    document: IDocument | undefined;
    fileName: string;
    className?: string;
}> = observer(({ document, fileName, className }) => {
    if (!document) {
        return null;
    }

    return (
        <NoRenderComponent
            className={classNames(className, 'no-file-renderer')}
        >
            <StyledTitle level={4} className="no-file-renderer__title">
                {t(
                    'Components.FilePreview.NoRender.Title',
                    `No renderer for this file`,
                )}
            </StyledTitle>
            <StyledText className="no-file-renderer__text">
                {fileName}
            </StyledText>
            <StyledBtn
                type="primary"
                icon={<DownloadOutlined />}
                className="no-file-renderer__download-btn"
                onClick={() => {
                    UrlHelper.downloadFile(document.uri, fileName);
                }}
            >
                {t('Components.FilePreview.NoRender.Download', 'Download')}
            </StyledBtn>
        </NoRenderComponent>
    );
});

const NoRenderComponent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 32px;
    gap: 16px;
    max-width: 420px;
    width: 400px;
    margin: 0 auto;
    align-items: center;
    border: 1px solid #4a4a4a;
    background: #313131eb;
`;

const StyledTitle = styled(Typography.Title)`
    &.ant-typography {
        color: #dfdfdf;
        text-align: center;
    }
`;

const StyledText = styled(Typography.Paragraph)`
    color: #dfdfdf;
    text-align: center;
`;

const StyledBtn = styled(Button)`
    .anticon {
        transform: translateY(-2px);
        font-size: 18px;
    }
`;
