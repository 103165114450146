import { CheckOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { LocalStoreHelper } from 'src/utils/LocalStoreHelper';
import styled from 'styled-components';

export const USER_CHANGED_LANG_KEY = 'isUserSet';

export const LanguageSwitcher = observer(() => {
    const [visible, setVisible] = useState(false);
    const { i18n } = useTranslation();
    const closeMenu = () => {
        setVisible(false);
    };

    if (!SettingsStore.hasFewLocales) {
        return null;
    }

    return (
        <StyledDropdown
            placement="bottomRight"
            arrow
            trigger={['click']}
            overlay={<Locales closeMenu={closeMenu} />}
            onVisibleChange={(isVisible) => setVisible(isVisible)}
            visible={visible}
        >
            <StyledButton>
                {i18n.language}
                <StyledIconContainerDropdown>
                    <DownOutlined />
                </StyledIconContainerDropdown>
            </StyledButton>
        </StyledDropdown>
    );
});

type LocalesProps = { closeMenu: () => void };
const Locales = observer(({ closeMenu }: LocalesProps) => {
    const { t, i18n } = useTranslation();

    return (
        <StyledMenu
            onClick={(e) => {
                e.domEvent.preventDefault();
                closeMenu();
            }}
        >
            {SettingsStore.supportedLocales.map((locale) => {
                return (
                    <MenuItem
                        key={locale}
                        onClick={() => {
                            LocalStoreHelper.setItem(
                                USER_CHANGED_LANG_KEY,
                                'true',
                            );
                            SettingsStore.setLocale(locale);
                        }}
                        className={`${
                            i18n.language === locale ? 'active' : ''
                        }`}
                    >
                        <StyledIconContainerLanguage
                            opacity={i18n.language !== locale}
                        >
                            <CheckOutlined />
                        </StyledIconContainerLanguage>
                        {t(`data:locale.${locale}`)}
                    </MenuItem>
                );
            })}
        </StyledMenu>
    );
});

const StyledDropdown = styled(Dropdown)`
    div {
        .ant-dropdown-arrow {
            background: var(--header-background) !important;
        }
    }
`;

const StyledButton = styled(Button)`
    height: 29px;
    background: var(--header-background);
    display: flex;
    align-items: center;
    font-size: 16px;
    border: none;
    text-transform: capitalize;
    margin: 0 20px;

    span {
        color: var(--header-text-color);
    }

    span:not(.anticon) {
        font-size: var(--header-font-size);
    }

    svg {
        fill: var(--header-text-color);
    }

    &:focus,
    &:hover {
        color: #0d0f1c;
    }
`;
const StyledMenu = styled(Menu)`
    border: none;
    border-radius: 4px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
        0 9px 28px 8px rgb(0 0 0 / 5%) !important;
    padding: 11px 11px 11px 0;
    flex: 1;
    .ant-menu-item {
        color: var(--header-text-color);
        .ant-menu-title-content {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &:after {
            display: none;
        }

        + .ant-menu-item {
            padding: 4px 19px;
        }

        &:hover {
            color: black;
        }

        &.ant-menu-item-selected,
        &:active {
            background-color: transparent;
        }
    }
    .active {
        color: #000000;
    }
`;

interface StyledIconContainerLanguageProps {
    opacity?: boolean;
}

const StyledIconContainerLanguage = styled.div<StyledIconContainerLanguageProps>`
    display: flex;
    justify-content: center;
    background: var(--header-background);
    align-items: center;
    margin: 0 3px;
    font-size: 15px;
    opacity: ${({ opacity }) => (opacity ? 0 : 1)};
`;

const StyledIconContainerDropdown = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--header-background);
    margin: 0 6px;
    font-size: 10px;
`;

const MenuItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    background: var(--header-background);
    color: var(--header-text-color);
    padding: 10px 19px 10px 10px;
    cursor: pointer;
    &:hover {
        color: #000000;
    }
`;
