import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { BasePage, PageType } from 'src/components/BasePage';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { ProcessDefinitionsStore } from 'src/stores/ProcessDefinitionsStore/ProcessDefinitionsStore';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

type ProcessesPageRouterParams = {
    processKey: string;
};
export const ProcessesPage = observer(() => {
    const { params } = useRouteMatch<ProcessesPageRouterParams>();
    const { t } = useTranslation();

    return (
        <BasePage
            pageName={`process-${params.processKey}`}
            type={PageType.SIMPLE_FORM}
        >
            <StyledPageTitle>
                {t('Page.Processes.Title', 'Apply for a loan')}
            </StyledPageTitle>

            <StyledContainer>
                {ProcessDefinitionsStore.mainMenuProcesses?.map((process) => (
                    <StyledProcessLink
                        to={UrlHelper.getTargetUrl(RoutePaths.process, {
                            processKey: process.key!,
                        })}
                        key={process.id}
                    >
                        {t(`${process.extensions?.l10nTitleKey}`)}
                    </StyledProcessLink>
                ))}
            </StyledContainer>
        </BasePage>
    );
});

export const StyledProcessLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 470px;
    height: 90px;
    border: 1px solid #dadee2;
    border-radius: 8px;
    color: #000000 !important;
    text-decoration: none !important;
    font-weight: 700;
    font-size: 15px;
    line-height: 135%;
    margin-bottom: 20px;
    color: #0d0f1c;
    &:hover {
        border: 3px solid #2c63e5;
    }
`;

export const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

export const StyledTitle = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

const StyledPageTitle = styled(Typography.Title)`
    font-weight: 700;
    line-height: 105%;
    letter-spacing: -0.03em;
    color: #0d0f1c;
    margin-bottom: 30px;
    text-align: center;

    &.ant-typography {
        margin-bottom: 43px;
        font-size: 48px;
        font-weight: 700;
    }

    @media (max-width: 576px) {
        &.ant-typography {
            margin-bottom: 30px;
            font-size: 32px;
        }
    }
`;
