import { LoanBorrowerAllowed } from 'src/generated-api-client';
import { borrowerApi, bpmsResourcesManagerApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class LoansStoreClass extends BasicStore<LoanBorrowerAllowed> {
    formKey = 'embedded:deployment:forms/custom/bp/cust-bp-04-loan.json';
    api: BasicStoreApi<LoanBorrowerAllowed> = {
        loadList: async () => {
            const response = await RequestHelper.unwrapFromAxiosPromise(
                borrowerApi.getBorrowerLoans(0, 100),
            );

            return response.content || [];
        },
        loadItem: (id: number) => {
            return RequestHelper.unwrapFromAxiosPromise(
                borrowerApi.getBorrowerLoan(id),
            );
        },

        loadItemForm: () => {
            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(this.formKey),
            );
        },
    };
}

export const LoansStore = new LoansStoreClass();
