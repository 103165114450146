/* eslint-disable max-lines */
import { Button, Input, Slider } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { ChromePicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { FormioForm } from 'src/components/FormioForm/FormioForm';
import form from 'src/pages/StylerPage/form.json';
import { ThemeStore } from 'src/stores/ThemeStore/ThemeStore';
import { LocalStoreHelper } from 'src/utils/LocalStoreHelper';
import styled from 'styled-components';

export const STYLES_LOCAL_STORAGE_KEY = 'styler';
export const LOGO_LOCAL_STORAGE_KEY = 'stylerLogo';

const PickerComponent = observer(
    ({
        title,
        onChange,
        currentValue,
    }: {
        title: string;
        onChange: any;
        currentValue: string;
    }) => {
        const [openPicker, setOpenPicker] = useState(false);
        const { t } = useTranslation();

        return (
            <StyledFlexColumnContainer>
                <StyledBlockTitle onClick={() => setOpenPicker(!openPicker)}>
                    {t(title)}
                </StyledBlockTitle>
                {openPicker && (
                    <StyledPicker
                        color={currentValue}
                        onChangeComplete={({ hex }) =>
                            (ThemeStore as any)?.[onChange]?.(hex)
                        }
                    />
                )}
            </StyledFlexColumnContainer>
        );
    },
);

const setLogoToLocalStorage = (value: File) => {
    getBase64(value).then((base64) => {
        LocalStoreHelper.setItem(LOGO_LOCAL_STORAGE_KEY, base64);
    });
};

const getBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
    });
};

export const StylerPage = observer(() => {
    const { t } = useTranslation();

    const exportTheme = () => {
        const fileData = JSON.stringify(
            ThemeStore.getThemeVariables,
            undefined,
            4,
        );
        const blob = new Blob([fileData], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = 'CssVariablesTheme.json';
        link.href = url;
        link.click();
    };

    const settingsMap = [
        {
            title: 'Page.Styler.Background',
            onChange: 'setBackgroundColor',
            currentValue: ThemeStore.getBackground,
        },
        {
            title: 'Page.Styler.App.Text.Color',
            onChange: 'setFormTextColor',
            currentValue: ThemeStore.getFormTextColor,
        },
        {
            title: 'Page.Styler.Header',
            onChange: 'setHeaderBg',
            currentValue: ThemeStore.getHeaderBackground,
        },
        {
            title: 'Page.Styler.Header.Text',
            onChange: 'setHeaderColor',
            currentValue: ThemeStore.getHeaderColor,
        },
        {
            title: 'Page.Styler.Header.Apply.Button',
            onChange: 'setApplyBtnBg',
            currentValue: ThemeStore.getHeaderApplyBtnBg,
        },
        {
            title: 'Page.Styler.Header.Apply.Button.Text',
            onChange: 'setApplyBtnColor',
            currentValue: ThemeStore.getHeaderApplyBtnColor,
        },
        {
            title: 'Page.Styler.Footer.Background',
            onChange: 'setFooterBg',
            currentValue: ThemeStore.getFooterBg,
        },
        {
            title: 'Page.Styler.Footer.Text.Color',
            onChange: 'setFooterTextColor',
            currentValue: ThemeStore.getFooterTextColor,
        },
        {
            title: 'Page.Styler.Label.Color',
            onChange: 'setLabelColor',
            currentValue: ThemeStore.getLabelColor,
        },
        {
            title: 'Page.Styler.Input.Border.Color',
            onChange: 'setInputBorderColor',
            currentValue: ThemeStore.getInputBorderColor,
        },
        {
            title: 'Page.Styler.Input.Background.Color',
            onChange: 'setInputBg',
            currentValue: ThemeStore.getInputBg,
        },
        {
            title: 'Page.Styler.Button.Background.Color',
            onChange: 'setFormButtonBg',
            currentValue: ThemeStore.getFormButtonsBg,
        },
    ];

    const resetStyles = () => {
        ThemeStore.setDefaultStyles();
        localStorage.removeItem(LOGO_LOCAL_STORAGE_KEY);
    };

    return (
        <StyledLoanPageWrapper>
            <StyledFlexContainer>
                <StyledContentContainer containerWidth={65}>
                    <FormioForm form={form} />
                </StyledContentContainer>
                <StyledContentContainer containerWidth={30}>
                    {settingsMap.map((item) => (
                        <PickerComponent
                            currentValue={item.currentValue}
                            title={item.title}
                            onChange={item.onChange}
                        />
                    ))}

                    <StyledFlexColumnContainer>
                        <StyledBlockTitle>
                            {t('Page.Styler.Header.Font.Size')}
                        </StyledBlockTitle>
                        <Slider
                            min={1}
                            max={20}
                            onChange={(value) =>
                                ThemeStore.setFontSize(value.toString())
                            }
                            value={
                                typeof Number(
                                    ThemeStore.getHeaderFontSize.replace(
                                        'px',
                                        '',
                                    ),
                                ) === 'number'
                                    ? Number(
                                          ThemeStore.getHeaderFontSize.replace(
                                              'px',
                                              '',
                                          ),
                                      )
                                    : 0
                            }
                        />
                    </StyledFlexColumnContainer>

                    <StyledFlexColumnContainer>
                        <StyledBlockTitle>
                            {t('Page.Styler.Input.Border.Radius')}
                        </StyledBlockTitle>
                        <Slider
                            min={1}
                            max={20}
                            onChange={(value) =>
                                ThemeStore.setInputBorderRadius(
                                    value.toString(),
                                )
                            }
                            value={
                                typeof Number(
                                    ThemeStore.getInputBorderRadius.replace(
                                        'px',
                                        '',
                                    ),
                                ) === 'number'
                                    ? Number(
                                          ThemeStore.getInputBorderRadius.replace(
                                              'px',
                                              '',
                                          ),
                                      )
                                    : 0
                            }
                        />
                    </StyledFlexColumnContainer>
                    <StyledFlexColumnContainer>
                        <StyledBlockTitle>
                            {t('Page.Styler.App.Font')}
                        </StyledBlockTitle>
                        <Input
                            value={ThemeStore.getFont}
                            onChange={(e) => ThemeStore.setFont(e.target.value)}
                        />
                    </StyledFlexColumnContainer>
                    <StyledFlexColumnContainer>
                        <StyledBlockTitle>
                            {t('Page.Styler.Logo')}
                        </StyledBlockTitle>
                        <Input
                            type="file"
                            onChange={(e) =>
                                setLogoToLocalStorage(
                                    e.target.files?.[0] as File,
                                )
                            }
                        />
                    </StyledFlexColumnContainer>
                    <StyledFlexColumnContainer>
                        <StyledBlockTitle>
                            {t('Page.Styler.Logo.Size')}
                        </StyledBlockTitle>
                        <Input
                            type="number"
                            onChange={(e) =>
                                ThemeStore.setLogoMaxSize(
                                    Number(e.target.value),
                                )
                            }
                        />
                    </StyledFlexColumnContainer>
                    <StyledFlexColumnContainer>
                        <Button onClick={resetStyles}>Reset styles</Button>
                    </StyledFlexColumnContainer>
                    <StyledFlexColumnContainer>
                        <Button onClick={exportTheme}>Export theme</Button>
                    </StyledFlexColumnContainer>
                </StyledContentContainer>
            </StyledFlexContainer>
        </StyledLoanPageWrapper>
    );
});

const StyledLoanPageWrapper = styled.span`
    width: 100%;
`;

const StyledFlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledContentContainer = styled.div<{ containerWidth: number }>`
    width: ${(props) => props.containerWidth}%;
`;

const StyledFlexColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
`;

const StyledBlockTitle = styled.h5`
    cursor: pointer;
`;

const StyledPicker = styled(ChromePicker)`
    margin-top: 5px;
`;
