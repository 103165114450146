import { History, PartialPath, State } from 'history';

// eslint-disable-next-line no-underscore-dangle
let _history: History;

const setHistory = (history: History) => {
    _history = history;
};

const navigate = (url: string | PartialPath, state?: State) => {
    _history.push(url, state);
};

const setQuerySearchParams = (params: string) => {
    _history.replace({
        search: params,
    });
};

const getQuerySearchParams = () => {
    return _history.location.search;
};

export const Router = {
    setHistory,
    navigate,
    setQuerySearchParams,
    getQuerySearchParams,
};
