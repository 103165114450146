import { Empty as AntEmpty } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Empty = () => {
    const { t } = useTranslation();

    return <StyledEmpty description={t('Empty.Description')} image={null} />;
};

const StyledEmpty = styled(AntEmpty)`
    .ant-empty-image {
        display: none;
    }
`;
