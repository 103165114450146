import { action, computed, observable } from 'mobx';
import { TaskRepresentation } from 'src/generated-api-client';
import { AssignedTasksStore } from 'src/stores/AssignedTasksStore/AssignedTasksStore';
import { CurrentTaskStore } from 'src/stores/CurrentTaskStore/CurrentTaskStore';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';

export class TaskViewViewModelClass {
    @observable isLoading = true;

    @action async loadItem(id: string) {
        try {
            await Promise.all([
                CurrentTaskStore.loadItem(id),
                CurrentTaskStore.loadForm(id),
                AssignedTasksStore.loadItem(id),
            ]);

            this.setCurrentTask();
        } catch (error) {
            console.error(error);
        } finally {
            this.setIsLoading(false);
        }
    }

    @action setCurrentTask() {
        this.taskDefinition = AssignedTasksStore.currentItem;
    }

    @action setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @observable taskDefinition?: TaskRepresentation;

    @computed get taskVariables() {
        return CurrentTaskStore.getInitialVariables();
    }

    @computed get taskForm() {
        return CurrentTaskStore.currentItemForm;
    }

    @computed get loadingStatus() {
        return new CombinedLoadingStatus([
            CurrentTaskStore.itemLoader,
            CurrentTaskStore.formLoader,
            AssignedTasksStore.itemLoader,
        ]);
    }

    @action async submitForm(id: string, values: any) {
        const data = await CurrentTaskStore.submitForm(id, values);
        await AssignedTasksStore.loadList();

        return data;
    }

    @action reset() {
        CurrentTaskStore.reset();
        AssignedTasksStore.reset();
        this.setIsLoading(true);
        this.taskDefinition = undefined;
    }
}

export const TaskViewViewModel = new TaskViewViewModelClass();
