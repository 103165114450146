/* eslint-disable max-lines */
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Footer } from 'src/components/Footer/Footer';
import { Header } from 'src/components/Header/Header';
import { SplashScreen } from 'src/components/SplashScreen/SplashScreen';
import { RoutesLayout } from 'src/containers/RoutesLayout/RoutesLayout';
import { StatusInfo } from 'src/core/StatusInfo';
import { AppStore } from 'src/stores/AppStore/AppStore';
import { AssignedTasksStore } from 'src/stores/AssignedTasksStore/AssignedTasksStore';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { ProcessDefinitionsStore } from 'src/stores/ProcessDefinitionsStore/ProcessDefinitionsStore';
import { ThemeStore } from 'src/stores/ThemeStore/ThemeStore';
import { CssVariables } from 'src/styles/CssVariables';
import styled from 'styled-components';

export const AppLayout = observer(() => {
    useEffect(() => {
        if (AuthStore.authenticated) {
            ProcessDefinitionsStore.loadList();
            AssignedTasksStore.loadList();
        }
    }, [AuthStore.authenticated]);

    if (!AuthStore.authenticated || !AppStore.started) {
        return <SplashScreen />;
    }

    return (
        <ThemeVariables themeColorsMap={ThemeStore.getThemeVariables}>
            <Header />
            <StyledContainer className="main-content">
                <RoutesLayout />
            </StyledContainer>
            <Footer />
        </ThemeVariables>
    );
});

const StyledContainer = styled.main`
    min-height: calc(
        100vh - ${CssVariables.footerHeight} - ${CssVariables.headerMargin} -
            ${CssVariables.headerHeight}
    );
    position: relative;
    padding: 0 20px;
`;

type ThemeVariablesProps = {
    themeColorsMap: { [key: string]: string | Record<string, string> };
};
const ThemeVariables = styled.div<ThemeVariablesProps>`
    /* Header */
    --header-background: ${(props) => props.themeColorsMap.headerBackground};
    --header-text-color: ${(props) => props.themeColorsMap.headerColor};
    --header-active-link-color: ${(props) =>
        props.themeColorsMap.headerActiveLinkColor};
    --header-apply-btn-bg: ${(props) => props.themeColorsMap.headerApplyBtnBg};
    --header-apply-btn-color: ${(props) =>
        props.themeColorsMap.headerApplyBtnColor};
    --header-apply-btn-border-color: ${(props) =>
        props.themeColorsMap.headerApplyBtnBorderColor};
    --header-font-size: ${(props) => props.themeColorsMap.headerFontSize};

    /*Body*/
    --form-text-color: ${(props) => props.themeColorsMap.formTextColor};
    --background: ${(props) => props.themeColorsMap.background};
    --app-font: ${(props) => props.themeColorsMap.font};
    --item-list-background: ${(props) => props.themeColorsMap.itemListBg};

    /*Form*/
    --label-color: ${(props) => props.themeColorsMap.labelColor};
    --input-border-color: ${(props) => props.themeColorsMap.inputBorderColor};
    --input-bg: ${(props) => props.themeColorsMap.inputBg};
    --input-border-color-hover: ${(props) =>
        props.themeColorsMap.inputBorderColorHover};
    --input-border-radius: ${(props) => props.themeColorsMap.inputBorderRadius};
    --form-button-color: ${(props) => props.themeColorsMap.formButtonsBg};
    --form-button-text-color: ${(props) =>
        props.themeColorsMap.formButtonsColor};
    --tab-color: ${(props) => props.themeColorsMap.tabsColor};
    --calculator-secondary-text: ${(props) =>
        props.themeColorsMap.calculatorSecondaryText};
    --calculator-slider-track: ${(props) =>
        props.themeColorsMap.calculatorSliderTrack};
    --calculator-input-color: ${(props) =>
        props.themeColorsMap.calculatorInputColor};
    --calculator-slider-handle-bg: ${(props) =>
        props.themeColorsMap.calculatorSliderHandleBg};
    --calculator-slider-handle-border: ${(props) =>
        props.themeColorsMap.calculatorSliderHandleBorder};
    --tabs-border-bottom-color: ${(props) =>
        props.themeColorsMap.tabsBorderBottomColor};
    --tabs-active-color: ${(props) => props.themeColorsMap.activeTabColor};
    --tabs-active-border-color: ${(props) =>
        props.themeColorsMap.activeTabBorderColor};

    /*Footer*/
    --footer-bg: ${(props) => props.themeColorsMap.footerBg};
    --footer-text-color: ${(props) => props.themeColorsMap.footerTextColor};

    background: ${(props) => props.themeColorsMap.background};

    /*Statuses*/
    --pending-color: ${(props) =>
        (props.themeColorsMap.statusColorsPending as StatusInfo).color};
    --pending-border-color: ${(props) =>
        (props.themeColorsMap.statusColorsPending as StatusInfo).borderColor};
    --pending-background: ${(props) =>
        (props.themeColorsMap.statusColorsPending as StatusInfo)
            .backgroundColor};

    --active-color: ${(props) =>
        (props.themeColorsMap.statusColorsActive as StatusInfo).color};
    --active-border-color: ${(props) =>
        (props.themeColorsMap.statusColorsActive as StatusInfo).borderColor};
    --active-background: ${(props) =>
        (props.themeColorsMap.statusColorsActive as StatusInfo)
            .backgroundColor};

    --repaid-color: ${(props) =>
        (props.themeColorsMap.statusColorsRepaid as StatusInfo).color};
    --repaid-border-color: ${(props) =>
        (props.themeColorsMap.statusColorsRepaid as StatusInfo).borderColor};
    --repaid-background: ${(props) =>
        (props.themeColorsMap.statusColorsRepaid as StatusInfo)
            .backgroundColor};

    --overdue-color: ${(props) =>
        (props.themeColorsMap.statusColorsOverdue as StatusInfo).color};
    --overdue-border-color: ${(props) =>
        (props.themeColorsMap.statusColorsOverdue as StatusInfo).borderColor};
    --overdue-background: ${(props) =>
        (props.themeColorsMap.statusColorsOverdue as StatusInfo)
            .backgroundColor};

    --processing-color: ${(props) =>
        (props.themeColorsMap.statusColorsProcessing as StatusInfo).color};
    --processing-border-color: ${(props) =>
        (props.themeColorsMap.statusColorsProcessing as StatusInfo)
            .borderColor};
    --processing-background: ${(props) =>
        (props.themeColorsMap.statusColorsProcessing as StatusInfo)
            .backgroundColor};

    --writtenOff-color: ${(props) =>
        (props.themeColorsMap.statusColorsWrittenOff as StatusInfo).color};
    --writtenOff-border-color: ${(props) =>
        (props.themeColorsMap.statusColorsWrittenOff as StatusInfo)
            .borderColor};
    --writtenOff-background: ${(props) =>
        (props.themeColorsMap.statusColorsWrittenOff as StatusInfo)
            .backgroundColor};

    --error-color: ${(props) =>
        (props.themeColorsMap.statusColorsError as StatusInfo).color};
    --error-border-color: ${(props) =>
        (props.themeColorsMap.statusColorsError as StatusInfo).borderColor};
    --error-background: ${(props) =>
        (props.themeColorsMap.statusColorsError as StatusInfo).backgroundColor};

    --kyc-color: ${(props) =>
        (props.themeColorsMap.statusColorsOnKyc as StatusInfo).color};
    --kyc-border-color: ${(props) =>
        (props.themeColorsMap.statusColorsOnKyc as StatusInfo).borderColor};
    --kyc-background: ${(props) =>
        (props.themeColorsMap.statusColorsOnKyc as StatusInfo).backgroundColor};

    --verification-color: ${(props) =>
        (props.themeColorsMap.statusColorsVerification as StatusInfo).color};
    --verification-border-color: ${(props) =>
        (props.themeColorsMap.statusColorsVerification as StatusInfo)
            .borderColor};
    --verification-background: ${(props) =>
        (props.themeColorsMap.statusColorsVerification as StatusInfo)
            .backgroundColor};

    --update-requested-color: ${(props) =>
        (props.themeColorsMap.statusColorsUpdateRequested as StatusInfo).color};
    --update-requested-border-color: ${(props) =>
        (props.themeColorsMap.statusColorsUpdateRequested as StatusInfo)
            .borderColor};
    --update-requested-background: ${(props) =>
        (props.themeColorsMap.statusColorsUpdateRequested as StatusInfo)
            .backgroundColor};

    --underwriting-color: ${(props) =>
        (props.themeColorsMap.statusColorsUnderwriting as StatusInfo).color};
    --underwriting-border-color: ${(props) =>
        (props.themeColorsMap.statusColorsUnderwriting as StatusInfo)
            .borderColor};
    --underwriting-background: ${(props) =>
        (props.themeColorsMap.statusColorsUnderwriting as StatusInfo)
            .backgroundColor};

    --declined-color: ${(props) =>
        (props.themeColorsMap.statusColorsDeclined as StatusInfo).color};
    --declined-border-color: ${(props) =>
        (props.themeColorsMap.statusColorsDeclined as StatusInfo).borderColor};
    --declined-background: ${(props) =>
        (props.themeColorsMap.statusColorsDeclined as StatusInfo)
            .backgroundColor};

    --canceled-color: ${(props) =>
        (props.themeColorsMap.statusColorsCanceled as StatusInfo).color};
    --canceled-border-color: ${(props) =>
        (props.themeColorsMap.statusColorsCanceled as StatusInfo).borderColor};
    --canceled-background: ${(props) =>
        (props.themeColorsMap.statusColorsCanceled as StatusInfo)
            .backgroundColor};

    --approved-color: ${(props) =>
        (props.themeColorsMap.statusColorsApproved as StatusInfo).color};
    --approved-border-color: ${(props) =>
        (props.themeColorsMap.statusColorsApproved as StatusInfo).borderColor};
    --approved-background: ${(props) =>
        (props.themeColorsMap.statusColorsApproved as StatusInfo)
            .backgroundColor};

    --disbursed-color: ${(props) =>
        (props.themeColorsMap.statusColorsDisbursed as StatusInfo).color};
    --disbursed-border-color: ${(props) =>
        (props.themeColorsMap.statusColorsDisbursed as StatusInfo).borderColor};
    --disbursed-background: ${(props) =>
        (props.themeColorsMap.statusColorsDisbursed as StatusInfo)
            .backgroundColor};
`;
