import { useEffect } from 'react';

type PandaDocEventTypes =
    | 'session_view.document.loaded'
    | 'session_view.document.completed'
    | 'session_view.document.exception';
const eventTypeToSubmit: Array<PandaDocEventTypes> = [
    'session_view.document.completed',
];

export const usePandaDoc = (formReference: React.MutableRefObject<any>) => {
    const onMessageHandler = (e: MessageEvent<any>) => {
        const eventType = getEventType(e.data);

        if (eventTypeToSubmit.includes(eventType)) {
            formReference.current?.formio?.submit();
        }
    };
    useEffect(() => {
        window.addEventListener('message', onMessageHandler);

        return () => {
            window.removeEventListener('message', onMessageHandler);
        };
    }, []);
};

const getEventType = (eventData: any) => {
    return eventData && eventData.type;
};
