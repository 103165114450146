import { UserOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import LogoutIconActive from 'src/assets/imgs/logout-icon-active.svg';
import LogoutIcon from 'src/assets/imgs/logout-icon.svg';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { Router } from 'src/core/router/Router';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { ProcessDefinitionsStore } from 'src/stores/ProcessDefinitionsStore/ProcessDefinitionsStore';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

const StyledMenuItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const ImageIcon = styled.img`
    width: 100%;
`;

const MenuItem = styled.div`
    background: var(--header-background);
    font-weight: 400;
    font-size: 15px;
    color: var(--header-text-color);
    padding: 4px 19px;
    cursor: pointer;
    .icon-container-active {
        display: none;
        margin-right: 7px;
    }
    .icon-container {
        margin-right: 7px;
    }
    &:hover {
        color: #000000;
        .icon-container {
            display: none;
        }
        .icon-container-active {
            display: block;
        }
    }
`;

const StyledMenu = styled(Menu)`
    border-radius: 4px;
    padding: 11px;
    background: var(--header-background);
`;

const menu = () => (
    <StyledMenu>
        {ProcessDefinitionsStore.accountManagementProcesses?.map((process) => (
            <MenuItem
                onClick={() => {
                    Router.navigate(
                        UrlHelper.getTargetUrl(RoutePaths.process, {
                            processKey: process.key!,
                        }),
                    );
                }}
                key={process.key}
            >
                <StyledMenuItem>
                    <Trans i18nKey={`${process.extensions?.l10nTitleKey}`} />
                </StyledMenuItem>
            </MenuItem>
        ))}
        <MenuItem
            onClick={() => {
                Router.navigate(RoutePaths.profile);
            }}
        >
            <Trans i18nKey="App.Header.Menu.Profile" />
        </MenuItem>
        <MenuItem
            onClick={() => {
                AuthStore.logout();
            }}
        >
            <StyledMenuItem>
                <div className="icon-container">
                    <ImageIcon src={LogoutIcon} />
                </div>
                <div className="icon-container-active">
                    <ImageIcon src={LogoutIconActive} />
                </div>
                <Trans i18nKey="App.Header.Menu.LogOut" />
            </StyledMenuItem>
        </MenuItem>
    </StyledMenu>
);

export const UserMenu = observer(() => {
    return (
        <Dropdown overlay={menu} placement="bottomRight" arrow>
            <StyledUserName>
                <StyledAvatar>
                    <UserOutlined />
                </StyledAvatar>
            </StyledUserName>
        </Dropdown>
    );
});

const StyledUserName = styled.div`
    color: white;
    display: flex;
    align-items: center;
`;

const StyledAvatar = styled.div`
    display: inline-flex;
    height: 38px;
    width: 38px;
    border-radius: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f7f8;
    text-transform: uppercase;
    font-weight: 700;
    color: #8b959e;
    cursor: pointer;
`;
