import { useResponsive } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { HeaderDesktop } from 'src/components/Header/HeaderDesktop';
import { HeaderMobile } from 'src/components/Header/HeaderMobile';

export const Header = observer(() => {
    const responsive = useResponsive();

    if (responsive.md) {
        return <HeaderDesktop />;
    }

    return <HeaderMobile />;
});
