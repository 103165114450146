import { observer } from 'mobx-react-lite';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { EntityDetails } from 'src/components/EntityPresentation/EntityDetails';
import { EntityProcess } from 'src/components/EntityPresentation/EntityProcess';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';

export type EntityPresentationProps = {
    store: BasicStore<any>;
};
export const EntityPresentation = observer(
    ({ store }: EntityPresentationProps) => {
        const { path, url } = useRouteMatch();

        return (
            <Switch>
                <Route
                    path={`${path}`}
                    exact
                    render={() => <EntityDetails store={store} />}
                />
                <Route
                    path={`${path}/process/:processKey`}
                    exact
                    render={() => <EntityProcess entityUrl={url} />}
                />
            </Switch>
        );
    },
);
