import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import { ListPage } from 'src/components/ListPage/ListPage';
import { ListPageItem } from 'src/components/ListPage/ListPageItem';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { EntityStatus } from 'src/core/StatusInfo';
import { Loan } from 'src/generated-api-client';
import { LoanStatusInfoMap } from 'src/pages/LoansPage/LoanStatusInfo';
import { LoansStore } from 'src/stores/LoansStore/LoansStore';
import { DateHelper } from 'src/utils/DateHelper';
import { NumberHelper } from 'src/utils/NumberHelper';
import { UrlHelper } from 'src/utils/UrlHelper';
import styled from 'styled-components';

export const LoansPage = observer(() => {
    const { t } = useTranslation();

    return (
        <ListPage
            pageName="loans"
            store={LoansStore}
            title={t('Page.Loans.Title')}
            renderItem={(item: Loan) => {
                const { termApproved } = item.loanApplication;
                const amountApproved = NumberHelper.formatToUsd(
                    item.loanApplication?.amountApproved!,
                );

                return (
                    <ListPageItem
                        to={UrlHelper.getTargetUrl(RoutePaths.loan, {
                            loanId: item.id!.toString(),
                        })}
                    >
                        <Row wrap={false} gutter={5}>
                            <Col flex="auto">
                                <StyledName>
                                    <Trans
                                        i18nKey="Page.Loans.ListItem.Title"
                                        count={termApproved}
                                    >
                                        {{ amountApproved }} for{' '}
                                        {{ termApproved }}
                                    </Trans>

                                    {t(
                                        `loan.period.type.${item?.loanApplication?.termPeriodType}`,
                                    )}
                                </StyledName>
                            </Col>
                            <Col flex="none">
                                <EntityStatus
                                    entityName="loan"
                                    statusInfoMap={LoanStatusInfoMap}
                                    status={item.status}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <StyledCreatedAt>
                                    {item.issueDate &&
                                        DateHelper.format(item.issueDate)}
                                </StyledCreatedAt>
                            </Col>
                            <Col>
                                <StyledIdContainer>
                                    #{item?.loanApplication.number}
                                </StyledIdContainer>
                            </Col>
                        </Row>
                    </ListPageItem>
                );
            }}
        />
    );
});

const StyledName = styled.div`
    font-weight: 600;
    font-size: 30px;
    line-height: 105%;
    letter-spacing: -0.03em;
    color: var(--form-text-color);

    @media (max-width: 576px) {
        font-size: 16px;
    }
`;

const StyledCreatedAt = styled.div`
    font-size: 16px;
    line-height: 165%;
    color: #828b94;
    margin-top: 12px;

    @media (max-width: 576px) {
        font-size: 11px;
    }
`;

const StyledIdContainer = styled.div`
    font-size: 16px;
    line-height: 165%;
    color: #828b94;
    margin-left: 1em;
    margin-top: 12px;

    @media (max-width: 576px) {
        font-size: 11px;
    }
`;
