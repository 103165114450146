import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BasePage } from 'src/components/BasePage';
import { Empty } from 'src/components/Empty/Empty';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';

export type ListPageProps = {
    title?: React.ReactNode;
    pageName: string;
    store: BasicStore<any>;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    renderItem: (item: any) => React.ReactNode;
};
export const ListPage: React.FC<ListPageProps> = observer(
    ({ store, pageName, title, renderItem }) => {
        useEffect(() => {
            store.loadList();
            () => {
                store.listLoader.reset();
            };
        }, []);

        return (
            <BasePage
                pageName={pageName}
                isLoading={store.listLoader.isLoading}
                title={title}
            >
                <List
                    dataSource={store.list}
                    renderItem={renderItem}
                    locale={{
                        emptyText: <Empty />,
                    }}
                />
            </BasePage>
        );
    },
);
