import { Spinner } from 'src/components/Spinner/Spinner';
import styled from 'styled-components';

export const SplashScreen = () => {
    return (
        <StyledAppSplashScreen>
            <Spinner />
        </StyledAppSplashScreen>
    );
};

const StyledAppSplashScreen = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
        45deg,
        rgba(33, 45, 59, 1) 0%,
        rgba(10, 12, 14, 1) 46%,
        rgba(33, 45, 59, 1) 92%
    );
`;
