import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ListPageItem = styled(Link)`
    background: var(--item-list-background);
    padding: 30px;
    display: block;
    color: #0d0f1c;
    &:hover {
        color: #0d0f1c;
        text-decoration: none;
        -webkit-box-shadow: 0px 2px 24px -22px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 2px 24px -22px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 2px 24px -22px rgba(0, 0, 0, 0.75);
    }
    border-radius: 6px;
    margin-bottom: 29px;
    min-height: 124px;

    @media (max-width: 576px) {
        padding: 25px 15px;
    }
`;
