import { observer } from 'mobx-react-lite';
import { useRouteMatch } from 'react-router-dom';
import { BasePage, PageType } from 'src/components/BasePage';
import { ProcessStartForm } from 'src/components/ProcessStartForm/ProcessStartForm';

export type ProcessPageRouterParams = {
    processKey: string;
};
export const ProcessPage = observer(() => {
    const { params } = useRouteMatch<ProcessPageRouterParams>();

    return (
        <BasePage
            pageName={`process-${params.processKey}`}
            type={PageType.SIMPLE_FORM}
        >
            <ProcessStartForm processKey={params.processKey} />
        </BasePage>
    );
});
